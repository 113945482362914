import { FC } from "react";
import "./neatStyles.scss";
import SummaryField from "../../SummaryField";
import { useAppSelector } from "../../../../../../store/hooks";
import { separateWithSpaces } from "../helpers";

interface Props {
    title: string;
  }
  
  const NeatSum: FC<Props> = ({ title }) => {
    const formValues = useAppSelector((state) => state.form);

    return (
      <div className="form-sum1"> 
        <h2 className="form-title">{title}</h2>
        <SummaryField
          nameProp="Name" 
          selected={formValues.fields.fieldName}
          border
        />
        <SummaryField
          nameProp="Street 1" 
          selected={formValues.fields.fieldStreet1}
          border
        />
        <SummaryField
          nameProp="Street 2" 
          selected={formValues.fields.fieldStreet2}
          border
        />
        <SummaryField
          nameProp="Post code" 
          selected={formValues.fields.fieldPost}
          border
        />
        <SummaryField
          nameProp="City" 
          selected={formValues.fields.fieldCity}
          border
        />
        <SummaryField
          nameProp="Country"
          selected={formValues.fields.fieldCountry}
          border
        />
        <SummaryField
          nameProp="Does a third-party manage the site?"
          selected={formValues.fields.companyName ? 'Yes' : 'No'}
          border
        />
        {formValues.fields.companyName && (
          <SummaryField
            nameProp="Company name"
            selected={formValues.fields.companyName}
            border
          />
        )}
        {/* <SummaryField
          nameProp="Country of the last production/processing site"
          selected={formValues.fields.fieldCountryOf}
          border
        /> */}
        <SummaryField
          nameProp="Chain of custody model"
          selected={separateWithSpaces(formValues.fields.fieldChain)}
        />
      </div>
    );
  };

export default NeatSum;