import { NEAT_SOURCE } from '.';
import { validateBatchForm } from '../BatchForm/validateBatchForm';

export const validate = async (
  formValues: any,
  validatePosNumber: (val: string) => Promise<boolean>,
  airportOptions: any,
) => {
  const { batchForm, fields: values } = formValues;

  const errors = {} as any;

  if (values?.endProductType?.length === 0) {
    errors.endProductType = 'Product type is required';
  }
  if (values?.sustainabilityCertification?.length === 0) {
    errors.sustainabilityCertification =
      'Sustainability certification is required';
  }

  const numberRegex = /^\d*\.?\d{0,3}$/;
  // sustainabilityCertification === 5 means "CORSIA" option is selected
  if (values?.sustainabilityCertification === 5) {
    if (values?.fieldSustainabilityCO2 && !numberRegex.test(values.fieldSustainabilityCO2)) {
      errors.fieldSustainabilityCO2 = 'Field must contain only numbers';
    }
  
    if (values?.fieldSustainabilityLand && !numberRegex.test(values.fieldSustainabilityLand)) {
      errors.fieldSustainabilityLand = 'Field must contain only numbers';
    }
  
    if (values?.fieldSustainabilityLandfill && !numberRegex.test(values.fieldSustainabilityLandfill)) {
      errors.fieldSustainabilityLandfill = 'Field must contain only numbers';
    }
  
    if (values?.fieldSustainabilityRecycling && !numberRegex.test(values.fieldSustainabilityRecycling)) {
      errors.fieldSustainabilityRecycling = 'Field must contain only numbers';
    }
  }
  if (!values?.feedstockCertification) {
    errors.feedstockCertification = 'Feedstock certification is required';
  }
  if (!values?.posIssueDate) {
    errors.posIssueDate = 'PoS issue date is required';
  }
  if (!values?.dateBlend && values?.endProduct === 'Blended' && values?.radioButton === 'Self-production') {
    errors.dateBlend = 'Blend date is required';
  }
  if (!values?.rawMaterialFeedstock) {
    errors.rawMaterialFeedstock = 'Raw material is required';
  }
  if (!values?.fieldBatchCountry) {
    errors.fieldBatchCountry = 'Country of feedstock origin';
  }
  if (!values?.conversionProcess) {
    errors.conversionProcess = 'Feedstock conversion process is required';
  }
  if (!values?.sustainabilityCertificationNumber) {
    errors.sustainabilityCertificationNumber =
      'Sustainability certification number is required';
  }
  if (!values?.lowerHeatingValue) {
    errors.lowerHeatingValue = 'Lower heating value is required';
  }
  if (!values?.fieldCertif || values?.fieldCertif?.length < 3) {
    errors.fieldCertif =
      'Field certification body must be longer than or equal to 3 characters';
  }
  if (!values?.fieldPoSNumber || values?.fieldPoSNumber?.length < 3) {
    errors.fieldPoSNumber =
      'Field PoS number of end product must be longer than or equal to 3 characters';
  }

  const isPoSIdExist = values?.fieldPoSNumber
    ? await validatePosNumber(values.fieldPoSNumber)
    : false;

  if (isPoSIdExist) {
    errors.fieldPoSNumber = 'PoS ID already exists in the system';
  }

  if (values?.fieldName?.length === 0) {
    errors.fieldName = 'Field Name is required';
  } else if (values?.fieldName.length < 2) {
    errors.fieldName = 'Field name must have at least 2 characters';
  }

  if (!values?.fieldStreet1) {
    errors.fieldStreet1 = 'Field street 1 is required';
  }

  if (!values?.fieldCountry) {
    errors.fieldCountry = 'Field country is required';
  }

  if (!values?.fieldChain) {
    errors.fieldChain = 'Field chain of custody model is required';
  }

  if (!values?.fieldPost || values?.fieldPost?.length < 3 || values?.fieldPost?.length > 10) {
    errors.fieldPost = 'Field must have at least 3 characters and no longer then 10 characters';
  }

  if (!values?.fieldCity) {
    errors.fieldCity = 'Field city is required';
  }

  if (values?.doesThirdPartyManage === 'Yes' && !values?.companyName) {
    errors.companyName = 'Company name is required';
  }

  if (values?.blendFieldName?.length === 0) {
    errors.blendFieldName = 'Field Name is required';
  } else if (values?.blendFieldName?.length < 2) {
    errors.blendFieldName = 'Field Name must have at least 2 characters';
  }

  if (!values?.blendFieldStreet1) {
    errors.blendFieldStreet1 = 'Field Street address is required';
  }

  if (!values?.blendFieldCountry) {
    errors.blendFieldCountry = 'Field Country is required';
  }
  
  if (!values?.blendFieldPost || values?.blendFieldPost?.length < 3 || values?.blendFieldPost?.length > 10) {
    errors.blendFieldPost = 'Field must have at least 3 characters and no longer then 10 characters';
  }

  if (!values?.blendFieldCity) {
    errors.blendFieldCity = 'Field City is required';
  }

  if (values?.radioButton === 'Supplier') {
    if (!values?.fieldPoS || values?.fieldPoS?.length < 3) {
      errors.fieldPoS =
        'Field PoS ID must be longer than or equal to 3 characters';
    }
    if (!values.supPosIssueDate) {
      errors.supPosIssueDate = 'Please enter supplier issue date';
    }
  }

  if (!values?.endProduct) {
    errors.endProduct = 'End product is required';
  }

  const { batchInformation } = validateBatchForm(
    { batchInformation: batchForm },
    undefined,
    false,
    airportOptions,
    false,
    true
  );

  if (batchInformation && batchInformation.length > 0) {
    errors.batch = batchInformation;
  }

  return errors;
};
