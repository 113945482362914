import { AbatementBaseline } from '@prisma/client';
import { COUNTRY } from '../../../../../dashboards/UserDashboard/BCURegistration/models/options.constant';

// remove any, dont have time to check the type
export const mapParseData = (data: any, baselines: AbatementBaseline[]) => {
  const {
    fieldID,
    fieldTonnes,
    fieldMJ,
    dateProd,
    entryBlendDate,
    dateBlend,
    fieldCO2,
    productDeliveryDate,
    doesThirdPartyManage,
    companyName,
    fieldBatchCountry,
    fieldBatchCountryOf,
    fieldRsb,
    fieldDec,
    sustainabilityCertification,
    conversionProcess,
    posIssueDate,
    supPosIssueDate,
    dateSustainabilitySAF,
    fieldSustainabilityCO2,
    fieldSustainabilityLand,
    fieldSustainabilityLandfill,
    fieldSustainabilityRecycling,
    fieldSaf,
    lowerHeatingValue,
    ...formValues
  } = data;
  const baseline = baselines?.find(
    (baseline: AbatementBaseline) =>
      baseline.name === sustainabilityCertification,
  );
  const isCorsia = sustainabilityCertification === 'ISCC CORSIA' || sustainabilityCertification === 'RSB CORSIA';

  const fieldGHG = (
    (100 - 100 * fieldCO2) /
    (baseline?.baselineValue || 100)
  ).toString();

  const modifiedConversionProcess = conversionProcess?.replace(/-/g, '_');

  const validatedFieldBatchCountry = COUNTRY.some(country => country.value === fieldBatchCountry)
    ? fieldBatchCountry
    : '';

  const validatedDateProd = dateProd === '-' ? '' : dateProd;
  const validatedEntryBlendDate = entryBlendDate === '-' ? '' : entryBlendDate;
  const validatedDateBlend = dateBlend === '-' ? '' : dateBlend;
  const validatedProductDeliveryDate = productDeliveryDate === '-' ? '' : productDeliveryDate;
  const validatedPosIssueDate = posIssueDate === '-' ? '' : posIssueDate;
  const validatedSupPosIssueDate = supPosIssueDate === '-' ? '' : supPosIssueDate;
  const validatedDateSustainabilitySAF = dateSustainabilitySAF === '-' ? '' : dateSustainabilitySAF;

  const truncateNumber = (numberStr: string, decimalPlaces: number): string => {
    if (!numberStr) return '';
    const [integerPart, fractionalPart = ''] = numberStr.split('.');
  
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    if (decimalPlaces === 0) {
      return formattedIntegerPart;
    }
  
    const paddedFraction =
      fractionalPart.length < decimalPlaces
        ? fractionalPart.padEnd(decimalPlaces, '0')
        : fractionalPart.slice(0, decimalPlaces);
  
    return `${formattedIntegerPart}.${paddedFraction}`;
  };

  const updatedCompanyName = doesThirdPartyManage === 'Yes' ? companyName : '';

  return {
    fields: {
      ...formValues,
      lowerHeatingValue: truncateNumber(lowerHeatingValue, 3),
      sustainabilityCertification: baseline?.id,
      conversionProcess: modifiedConversionProcess,
      fieldBatchCountry: validatedFieldBatchCountry,
      posIssueDate: validatedPosIssueDate,
      supPosIssueDate: validatedSupPosIssueDate,
      dateSustainabilitySAF: validatedDateSustainabilitySAF,
      fieldSustainabilityCO2: isCorsia ? truncateNumber(fieldSustainabilityCO2, 3) : '',
      fieldSustainabilityLand: isCorsia ? truncateNumber(fieldSustainabilityLand, 3) : '',
      fieldSustainabilityLandfill: isCorsia ? truncateNumber(fieldSustainabilityLandfill, 3) : '',
      fieldSustainabilityRecycling: isCorsia ? truncateNumber(fieldSustainabilityRecycling, 3) : '',
      companyName: updatedCompanyName,
      doesThirdPartyManage: doesThirdPartyManage,
    },
    batchForm: [
      {
        fieldID,
        fieldTonnes: truncateNumber(fieldTonnes, 5),
        fieldMJ: truncateNumber(fieldMJ, 0),
        dateBlend: validatedDateBlend,
        dateProd: validatedDateProd,
        entryBlendDate: validatedEntryBlendDate,
        fieldCO2: truncateNumber(fieldCO2, 2),
        fieldGHG,
        productDeliveryDate: validatedProductDeliveryDate,
        fieldBatchCountryOf,
        fieldSaf,
        fieldRsb,
        fieldDec: fieldDec,
      },
    ],
  };
};
