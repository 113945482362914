import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { UserRole, Prisma, AbatementBaseline } from '@prisma/client';
import StatusComponent from '../StatusComponent';
import {
  CHAIN_OF_CUSTODY_MODEL,
  CONVERSION_PROCESSES,
  INCENTIVES_TAX_CREDITS,
  PRODUCT_TYPES,
  RAW_MATERIALS,
  REPORTING,
  NEAT_PRODUCT_SOURCE_SUMMARY,
  END_PRODUCT_INFO,
} from '../../dashboards/UserDashboard/BCURegistration/models/options.constant';
import { STATUSES } from '../../dashboards/AdminDashboard/BCUList/models/options.constant';
import { CompanyType } from '../../../structure/models/company/company';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { DatePicker, Input, Select } from 'antd';
import { EditButtons } from '../EditButtons/EditButtons';
import DataTable, {
  DataKeyType,
  ProcessedDataKeyType,
} from '../DataTable/DataTable';
import { SearchAirportInput } from '../SearchAirportInput/SearchAirportInput';
import {
  DATE_FORMAT,
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../core/models/contstants/format-date';
import convertToNumber from '../../../helpers/convertToNumber';
import { toOptions } from '../../../helpers/toOptions';
import { RcFile } from 'antd/lib/upload';
import {
  addAttachment,
  downloadAttachment,
  removeAttachment,
} from '../../../API/attachments';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RelationTypes } from '../../../API/admin/notes';
import fileDownload from 'js-file-download';
import { Attachment, UploadAttachment } from '../Attachment/Attachment';
import { diff } from 'deep-object-diff';
import { useNotification } from '../../../hooks/useNotification';
import { Big } from '../../../helpers/big';
import { BatchFormFields } from '../../../store/reducers/user/bcuFormReducer';
import { isInTypes } from '../../../helpers/types';
import { COUNTRIES } from '../../../constants/countries.constant';
import { useGetAbatementBaselinesQuery } from '../../../API';
import { limitOfNumbers } from '../../../helpers/limitOfNumbers';
import { BCUStatuses } from '../../../structure/models/bcu/bcu';
import { selectBaseLines, selectFeedstockCerts } from '../../../store/reducers/user/abatementBaselinesReducer';
import { fetchFeedstockCerts } from '../../../API/credits';

import './BCUData.scss';

const DateFnsDatePicker = DatePicker.generatePicker<Date>(
  dateFnsGenerateConfig,
);

export const BatchPreviewData: React.FC<{ batches: BatchFormFields[] }> = ({
  batches,
}) => {
  const toFixed = (value: any, precision = 2) => {
    if (typeof value === 'number') {
      return value?.toLocaleString('en-US', {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      });
    }
    if (typeof value === 'string') {
      return parseFloat(value?.replaceAll(',', ''))?.toLocaleString('en-US', {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      });
    }
    return value;
  };

  const isDataBlendedExist = useMemo(() => {
    return batches?.some(batch => batch.dateBlend) || false;
  }, [batches]);

  const columns: ColumnsType<BatchFormFields> = [
    {
      title: 'Batch ID end product',
      dataIndex: 'fieldID',
      key: 'fieldID',
    },
    {
      title: 'Neat amount in tonnes',
      dataIndex: 'fieldTonnes',
      key: 'fieldTonnes',
      render: (value) => toFixed(value, 5),
    },
    {
      title: 'Neat amount in MJ',
      dataIndex: 'fieldMJ',
      key: 'fieldMJ',
      render: (value) => toFixed(value, 0),
    },
    {
      title: 'Date of production',
      dataIndex: 'dateProd',
      key: 'dateProd',
      render: (value: any) => formatDate(value, 'dd.MM.yyyy', true),
    },
    ...(isDataBlendedExist
      ? [
          {
            title: 'Date of blending',
            dataIndex: 'dateBlend',
            key: 'dateBlend',
            render: (value: any) => formatDate(value, 'dd.MM.yyyy', true),
          },
        ]
      : []),
    {
      title: 'Date of entry in tracking system',
      dataIndex: 'entryBlendDate',
      key: 'entryBlendDate',
      render: (value: any) => formatDate(value, 'dd.MM.yyyy', true),
    },
    {
      title: 'Country of feedstock origin',
      dataIndex: 'fieldBatchCountry',
      key: 'fieldBatchCountry',
    },
    {
      title: 'LCA GHG value [gCO₂eq/MJ]',
      dataIndex: 'fieldCO2',
      key: 'fieldCO2',
      render: (value) => toFixed(value, 2),
    },
    {
      title: 'GHG emission reduction [%]',
      dataIndex: 'fieldGHG',
      key: 'fieldGHG',
      render: (value) => toFixed(value, 3),
    },
    {
      title: 'Date of end product delivery to buyer',
      dataIndex: 'productDeliveryDate',
      key: 'productDeliveryDate',
      render: (value: any) => formatDate(value, 'dd.MM.yyyy', true),
    },
    {
      title: 'Country of physical end product delivery',
      dataIndex: 'fieldBatchCountryOf',
      key: 'fieldBatchCountryOf',
    },
    {
      title: 'Airport of physical end product delivery',
      dataIndex: 'fieldSaf',
      key: 'fieldSaf',
    },
    {
      title: 'Additional claim',
      dataIndex: 'fieldRsb',
      key: 'fieldRsb',
    },
    {
      title: 'Declaration of incentives and tax credits',
      dataIndex: 'fieldDec',
      key: 'fieldDec',
      render: (value: any) => value?.replace(/,(\S)/g, ', '),
    },
  ];

  return (
    <div className="c-batch-data">
      <Table
        className="c-batch-data-table"
        columns={columns}
        dataSource={batches}
        rowKey="fieldID"
        pagination={false}
      />
    </div>
  );
};

export const BatchData: React.FC<{
  batches: {
    fieldID: any;
    volume: any;
    neatAmountInMJ: any;
    dateBlend: any;
    fieldCO2: any;
    fieldGHG: any;
    dateProd?: any;
    entryBlendDate?: any;
    fieldBatchCountry?: any;
    fieldBatchCountryOf?: any;
    fieldDec?: any;
    fieldRsb?: any;
    fieldSaf?: any;
    productDeliveryDate?: any;
  }[];
  endProduct?: any;
  productSource?: any;
  editable: boolean;
  baselineValue?: number;
  onChange?: (batches: any[]) => any;
}> = ({ batches, editable, onChange, baselineValue = 100, endProduct, productSource }) => {
  return (
    <div className="c-batch-data">
      <table className="c-batch-data-table">
        <thead>
          <tr className="c-batch-data-table__head-row">
            <th className="c-batch-data-table__head-cell">
              Batch ID end product
            </th>
            <th className="c-batch-data-table__head-cell">
              Neat amount in tonnes
            </th>
            <th className="c-batch-data-table__head-cell">Neat amount in MJ</th>
            <th className="c-batch-data-table__head-cell">Date of production</th>
            {(endProduct === 'Blended' && productSource !== 'Supplier') && (
              <th className="c-batch-data-table__head-cell">Date of blending</th>
            )}
            <th className="c-batch-data-table__head-cell">Date of entry in tracking system</th>
            <th className="c-batch-data-table__head-cell">
              LCA GHG value [gCO<sub>2</sub>eq/MJ]
            </th>
            <th className="c-batch-data-table__head-cell">
              GHG emission reduction [%]
            </th>
            <th className="c-batch-data-table__head-cell">Date of end product delivery to buyer</th>
          </tr>
        </thead>
        <tbody>
          {(Array.isArray(batches) ? batches : []).map((batch, i) => (
            <tr key={`bt_${i}`} className="c-batch-data-table__row">
              <td className="c-batch-data-table__cell">
                {!editable && batch.fieldID}
                {editable && (
                  <div>
                    <Input
                      size="small"
                      placeholder="Batch ID"
                      type="text"
                      defaultValue={batch.fieldID}
                      onChange={e => {
                        const fieldID = e.target.value || '';

                        if (onChange) {
                          onChange(
                            batches.map((b, y) => {
                              if (y !== i) return b;

                              return {
                                ...batch,
                                fieldID,
                              };
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </td>
              <td className="c-batch-data-table__cell">
                {!editable &&
                  batch.volume &&
                  batch.volume?.toLocaleString('en-US', {
                    maximumFractionDigits: 5,
                    minimumFractionDigits: 5,
                  })}
                {editable && (
                  <div>
                    <Input
                      size="small"
                      className="ant-input--no-controls"
                      placeholder="Neat amount, tonnes"
                      type="number"
                      defaultValue={batch.volume}
                      onChange={e => {
                        if (onChange) {
                          const value = convertToNumber(e.target.value);

                          onChange(
                            batches.map((b, y) => {
                              if (y !== i) return b;

                              return {
                                ...batch,
                                volume: value,
                              };
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </td>
              <td className="c-batch-data-table__cell">
                {!editable &&
                  batch?.neatAmountInMJ?.toLocaleString('en-US', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}
                {editable && (
                  <div>
                    <Input
                      size="small"
                      className="ant-input--no-controls"
                      placeholder="Neat amount, MJ"
                      type="number"
                      defaultValue={batch.neatAmountInMJ}
                      onChange={e => {
                        if (onChange) {
                          const value = convertToNumber(e.target.value);

                          onChange(
                            batches.map((b, y) => {
                              if (y !== i) return b;

                              return {
                                ...batch,
                                neatAmountInMJ: value,
                              };
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </td>
              <td
                className="c-batch-data-table__cell"
                style={{ minWidth: '80px' }}>
                {!editable && formatDate(batch.dateProd, 'dd.MM.yyyy', true)}
                {editable && (
                  <div>
                    <DateFnsDatePicker
                      size="small"
                      placeholder="Date of production"
                      format="dd.MM.yyyy"
                      defaultValue={new Date(batch.dateProd || Date.now())}
                      onChange={dateProd => {
                        if (onChange) {
                          onChange(
                            batches.map((b, y) => {
                              if (y !== i) return b;
                              return {
                                ...batch,
                                dateProd: dateProd || undefined,
                              };
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </td>
              {(endProduct === 'Blended' && productSource !== 'Supplier') && (
                <td
                  className="c-batch-data-table__cell"
                  style={{ minWidth: '80px' }}>
                  {!editable && formatDate(batch.dateBlend, 'dd.MM.yyyy', true)}
                  {editable && (
                    <div>
                      <DateFnsDatePicker
                        size="small"
                        placeholder="Date of blending"
                        format="dd.MM.yyyy"
                        defaultValue={new Date(batch.dateBlend || Date.now())}
                        onChange={dateBlend => {
                          if (onChange) {
                            onChange(
                              batches.map((b, y) => {
                                if (y !== i) return b;

                                return {
                                  ...batch,
                                  dateBlend: dateBlend || undefined,
                                };
                              }),
                            );
                          }
                        }}
                      />
                    </div>
                  )}
                </td>
              )}
              <td
                className="c-batch-data-table__cell"
                style={{ minWidth: '80px' }}>
                {!editable && formatDate(batch.entryBlendDate, 'dd.MM.yyyy', true)}
                {editable && (
                  <div>
                    <DateFnsDatePicker
                      size="small"
                      placeholder="Date of production"
                      format="dd.MM.yyyy"
                      defaultValue={new Date(batch.entryBlendDate || Date.now())}
                      onChange={entryBlendDate => {
                        if (onChange) {
                          onChange(
                            batches.map((b, y) => {
                              if (y !== i) return b;
                              return {
                                ...batch,
                                entryBlendDate: entryBlendDate || undefined,
                              };
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </td>
              <td className="c-batch-data-table__cell">
                {!editable &&
                  batch?.fieldCO2?.toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                {editable && (
                  <div>
                    <Input
                      size="small"
                      className="ant-input--no-controls"
                      placeholder="LCA GHG value"
                      type="number"
                      defaultValue={batch.fieldCO2}
                      onChange={e => {
                        if (onChange) {
                          const value = convertToNumber(e.target.value);
                          const fieldGHG = parseFloat(
                            (
                              100 -
                              (100 * value) / baselineValue
                            )?.toLocaleString('en-US', {
                              maximumFractionDigits: 3,
                              minimumFractionDigits: 3,
                            }),
                          );

                          onChange(
                            batches.map((b, y) => {
                              if (y !== i) return b;

                              return {
                                ...batch,
                                fieldGHG,
                                fieldCO2: value,
                              };
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </td>
              <td className="c-batch-data-table__cell">
                {!editable &&
                  batch?.fieldGHG?.toLocaleString('en-US', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3,
                  })}
                {editable && (
                  <div>
                    <Input
                      size="small"
                      className="ant-input--no-controls"
                      placeholder="GHG emission reduction"
                      type="number"
                      disabled
                      value={batch.fieldGHG}
                    />
                  </div>
                )}
              </td>
              <td
                className="c-batch-data-table__cell"
                style={{ minWidth: '80px' }}>
                {!editable && formatDate(batch.productDeliveryDate, 'dd.MM.yyyy', true)}
                {editable && (
                  <div>
                    <DateFnsDatePicker
                      size="small"
                      placeholder="Date of end product delivery to buyer"
                      format="dd.MM.yyyy"
                      defaultValue={new Date(batch.productDeliveryDate || Date.now())}
                      onChange={productDeliveryDate => {
                        if (onChange) {
                          onChange(
                            batches.map((b, y) => {
                              if (y !== i) return b;
                              return {
                                ...batch,
                                productDeliveryDate: productDeliveryDate || undefined,
                              };
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const getBCUInfoKeys = (
  data: any,
  baselines: AbatementBaseline[] = [],
  editable = false,
): { fields: DataKeyType[]; label?: string | ReactNode }[] => {
  // TODO: Refactor this block
  const getDateProdOptions = () => {
    const currentYear = new Date().getFullYear();
    const minYear = 1990;
    const yearRange = currentYear + 1 - minYear;
    const years = Array.from(
      Array(yearRange),
      (_, x) => `${minYear + x}`,
    ).reverse();

    return toOptions(years);
  };

  const dateProdOptions = getDateProdOptions();

  const baselineOptions = (baselines || []).map(baseline => ({
    label: baseline.name,
    value: baseline.name,
  }));

  return [
    {
      fields: [
        {
          label: 'BCU ID',
          type: 'text',
          editable: false,
          value: v => v?.BCUID,
        },
        {
          label: 'Amount of BCUs',
          key: 'volume',
          type: 'number',
          value: v => limitOfNumbers(v.volume || '0', 3),
        },
        {
          label: (
            <span>
              CO<sub>2</sub>eq reduction (t)
            </span>
          ),
          key: 'tonnesOfCO2eq',
          type: 'number',
          editable: true,
          value: v => limitOfNumbers(v.tonnesOfCO2eq || '0', 3),
        },
        {
          label: (
            <span>
              CO<sub>2</sub>eq reduction (t) / BCU
            </span>
          ),
          key: 'tonnesOfCO2eq',
          type: 'number',
          editable: true,
          value: v => {
            try {
              return limitOfNumbers(
                (v.tonnesOfCO2eq || 0) / (v.volume || 1),
                3,
              );
            } catch (e) {
              console.warn(e);
            }

            return new Big(0).toFixed(3);
          },
        },
        {
          label: (
            <span>
              LCA value (gCO<sub>2</sub>eq/MJ)
            </span>
          ),
          key: 'LCAGHGValue',
          type: 'number',
          editable: true,
          value: v => limitOfNumbers(v.LCAGHGValue || '0', 2),
        },
        {
          label: (
            <span>
              CO<sub>2</sub>eq Reduction (%)
            </span>
          ),
          key: 'GHGEmissionReduction',
          type: 'number',
          editable: true,
          value: v => v.GHGEmissionReduction,
        },
        {
          label: 'Amount of product (t)',
          key: 'volume',
          type: 'number',
          value: v => limitOfNumbers(v.volume || '0', 2),
          meta: {
            style: {
              textAlign: 'right',
              paddingRight: '75px',
            },
          },
        },
        {
          label: 'Amount of product (MJ)',
          key: 'neatAmountInMJ',
          type: 'number',
          value: v => limitOfNumbers(v.neatAmountInMJ || '0', 0),
          meta: {
            style: {
              textAlign: 'right',
              paddingRight: '75px',
            },
          },
        },
        {
          label: 'Sustainability certification',
          key: 'certificationScheme',
          type: 'select',
          editable: true,
          options: () => baselineOptions,
          value: v => v.certificationScheme,
          render: v => v.abatementBaseline?.name || v.certificationScheme || '',
        },
        {
          label: (
            <span>
              Fossil baseline (gCO<sub>2</sub>eq/MJ)
            </span>
          ),
          key: 'abatementBaselineScheme',
          editable: false,
          value: v => limitOfNumbers(v.abatementBaseline?.baselineValue, 0),
        },
        {
          label: 'Year of production',
          key: 'dateProd',
          type: 'date',
          editable: true,
          value: v => v.dateProd || '',
          render: v => new Date(v.dateProd).getFullYear(),
        },
        {
          label: 'Country of blending',
          key: 'blendingCountry',
          type: 'select',
          options: () => COUNTRIES,
          editable: true,
          value: v => v.blendingCountry || '',
        },
        {
          label: 'Feedstock (raw material)',
          key: 'rawMaterial',
          editable: true,
          type: 'select',
          options: () => RAW_MATERIALS,
          value: v => v.rawMaterial,
          render: v =>
            RAW_MATERIALS.find(o => o.value === v.rawMaterial)?.label || '',
        },
        {
          label: 'Feedstock conversion process',
          key: 'feedstockConversionProcess',
          editable: true,
          type: 'select',
          options: () => CONVERSION_PROCESSES,
          value: v => v.feedstockConversionProcess,
          render: v =>
            CONVERSION_PROCESSES.find(
              o => o.value === v.feedstockConversionProcess,
            )?.label || '',
        },
        {
          label: 'Feedstock country of origin',
          editable: true,
          type: 'select',
          options: () => COUNTRIES,
          key: 'feedstockOriginWithSCSDetail',
          value: v => v.feedstockOriginWithSCSDetail,
        },
        {
          label: 'Location of physical end product delivery',
          key: 'airportOfPhysicalEndProductDelivery',
          editable: true,
          value: v =>
            `${v?.airportOfPhysicalEndProductDeliveryData?.name || ''} ${
              v?.airportOfPhysicalEndProductDeliveryData?.code || ''
            } ${v?.airportOfPhysicalEndProductDeliveryData?.country || ''}`,
          renderEdit: (v, onChange) => {
            return (
              <SearchAirportInput
                className="c-data-table__input"
                country={v.countryOfPhysicalEndProductDelivery}
                value={v.airportOfPhysicalEndProductDelivery}
                onChange={(v: any) => onChange(v)}
              />
            );
          },
        },
        {
          label: 'Declaration of incentives',
          key: 'incentivesSAFDeclarationAndTaxCredits',
          editable: true,
          options: () => INCENTIVES_TAX_CREDITS,
          value: v => v.incentivesSAFDeclarationAndTaxCredits?.replace(/,(\S)/g, ', $1'),
          renderEdit: (v, onChange) => {
            return (
              <Select
                className="c-data-table__input"
                showSearch={true}
                mode="multiple"
                options={INCENTIVES_TAX_CREDITS}
                value={(typeof v.incentivesSAFDeclarationAndTaxCredits ===
                'string'
                  ? v.incentivesSAFDeclarationAndTaxCredits
                  : ''
                )
                  .split(',')
                  .map((v: string) => v.trim())}
                onChange={(v: any) =>
                  onChange(Array.isArray(v) ? v.join(', ') : '')
                }
              />
            );
          },
        },
        {
          label: 'Country where BCU will be claimed',
          key: 'creditClaimCountry',
          type: 'select',
          options: () => COUNTRIES,
          editable: true,
          value: v => v.creditClaimCountry,
        },
        {
          label: 'Type of reporting',
          editable: true,
          type: 'select',
          options: () => REPORTING,
          key: 'reporting',
          value: v => v.reporting,
        },
        {
          label: 'BCU registration date',
          editable: false,
          value: v =>
            formatDate(
              v.registrationApproveDate,
              DATE_WITH_TIME_FORMAT,
              false,
              '',
            ),
        },
        {
          label: 'BCU expiration date',
          editable: false,
          value: v =>
            formatDate(v.statusExpiration, DATE_WITH_TIME_FORMAT, false, ''),
        },
        {
          label: 'BCU retirement statement number',
          editable: false,
          value: v => v.retirementStatement?.RSID,
        },
        {
          label: 'BCU status',
          key: 'status',
          editable: true,
          type: 'select',
          options: () => STATUSES,
          render: v => <StatusComponent statusOfComponent={v.status} />,
          value: v => v.status,
        },
      ],
    },
  ];
};

export const getBCUKeys = (
  data: any,
  showFull = false,
  handleUploadFile = (kind: string, key: string) => (file: RcFile) =>
    Promise.resolve(false),
  handleOpenFile = (file: UploadAttachment) => {},
  handleRemoveFile = (key: string) => (file: UploadAttachment) =>
    Promise.resolve(false),
  baselines: AbatementBaseline[],
  editable = false,
  feedstockCertsOptions: any[] = [],
): { fields: DataKeyType[]; label?: string | ReactNode }[] => {
  const baselineValue = data?.abatementBaseline?.baselineValue || 1;
  const isCorsiaStepExist = data?.certificationScheme?.includes('CORSIA') || false;
  // TODO: Refactor this block
  const getDateProdOptions = () => {
    const currentYear = new Date().getFullYear();
    const minYear = 1990;
    const yearRange = currentYear + 1 - minYear;
    const years = Array.from(
      Array(yearRange),
      (_, x) => `${minYear + x}`,
    ).reverse();

    return toOptions(years);
  };

  const dateProdOptions = getDateProdOptions();

  const baselineOptions = (baselines || []).map(baseline => ({
    label: baseline.name,
    value: baseline.name,
  }));

  if (!showFull) {
    return [
      {
        fields: [
          {
            label: 'BCU ID',
            type: 'text',
            editable: false,
            value: v => v?.BCUID,
          },
          {
            label: 'End product type',
            key: 'productType',
            type: 'select',
            options: () => {
              return PRODUCT_TYPES;
            },
            editable: true,
            value: v => v.productType,
          },
          {
            label: 'Name',
            key: 'supplierName',
            type: 'text',
            editable: true,
            value: v => v.supplierName,
          },
          {
            label: (
              <span>
                Final LCA GHG value (gCO<sub>2</sub>eq/MJ)
              </span>
            ),
            key: 'LCAGHGValue',
            type: 'number',
            editable: true,
            value: v =>
              v.LCAGHGValue?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
          },
          {
            label: 'Final GHG Emission Reduction (weighted, %)',
            key: 'GHGEmissionReduction',
            type: 'number',
            editable: true,
            value: v =>
              v.GHGEmissionReduction?.toLocaleString('en-US', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              }),
          },
          {
            label: 'Neat amount in MJ',
            key: 'neatAmountInMJ',
            type: 'number',
            editable: true,
            value: v => v.neatAmountInMJ,
          },
          {
            label: 'Total neat amount (t)',
            key: 'volume',
            type: 'number',
            editable: true,
            value: v => v.volume,
          },
          {
            label: (
              <span>
                Total CO<sub>2</sub>eq reduction (t)
              </span>
            ),
            key: 'tonnesOfCO2eq',
            type: 'number',
            editable: true,
            value: v => v.tonnesOfCO2eq,
          },
          {
            label: 'Sustainability certification',
            key: 'certificationScheme',
            type: 'select',
            editable: true,
            options: () => baselineOptions,
            value: v => v.certificationScheme,
            render: v =>
              v.abatementBaseline?.name || v.certificationScheme || '',
          },
          {
            label:
              'If applicable, disaggregated value for direct land use charge',
            key: 'directLandUseChangeDisaggregatedValue',
            editable: true,
            type: 'text',
            value: v => v.directLandUseChangeDisaggregatedValue,
          },
          {
            label: 'Feedstock conversion process',
            key: 'feedstockConversionProcess',
            editable: true,
            type: 'select',
            options: () => CONVERSION_PROCESSES,
            value: v => v.feedstockConversionProcess,
            render: v =>
              CONVERSION_PROCESSES.find(
                o => o.value === v.feedstockConversionProcess,
              )?.label || '',
          },
          {
            label: `Batches`,
            key: 'batches',
            value: () => null,
            render: () =>
              data.batches && (
                <BatchData
                  batches={data.batches}
                  editable={false}
                  baselineValue={baselineValue}
                />
              ),
            renderEdit: (v: any, onChange: (value: any) => any) =>
              data.batches && (
                <BatchData
                  batches={data.batches}
                  editable={true}
                  baselineValue={baselineValue}
                  onChange={batches => {
                    onChange(batches);
                  }}
                />
              ),
          },
          {
            label: 'Date of neat production',
            key: 'yearOfNeatProduction',
            type: 'select',
            options: () => dateProdOptions,
            editable: true,
            value: v => v.yearOfNeatProduction,
          },
          {
            label: 'Raw material (feedstock)',
            key: 'rawMaterial',
            editable: true,
            type: 'select',
            options: () => RAW_MATERIALS,
            value: v => v.rawMaterial,
            render: v =>
              RAW_MATERIALS.find(o => o.value === v.rawMaterial)?.label || '',
          },
          {
            label: 'Production date of Neat SAF',
            key: 'neatSAFProductionDate',
            editable: true,
            type: 'date',
            value: v => v.neatSAFProductionDate,
          },
          {
            label: 'Country of physical end product delivery',
            editable: true,
            type: 'text',
            key: 'countryOfPhysicalEndProductDelivery',
            value: v => v.countryOfPhysicalEndProductDelivery,
          },
          {
            label: 'Airport of physical end product delivery',
            editable: true,
            type: 'text',
            key: 'airportOfPhysicalEndProductDelivery',
            value: v =>
              v?.airportOfPhysicalEndProductDeliveryData
                ? `${v?.airportOfPhysicalEndProductDeliveryData?.name || ''} ${
                    v?.airportOfPhysicalEndProductDeliveryData?.code || ''
                  } ${v?.airportOfPhysicalEndProductDeliveryData?.country || ''}`
                : v.airportOfPhysicalEndProductDelivery,
          },
          {
            label: 'Reporting',
            editable: true,
            type: 'select',
            options: () => REPORTING,
            key: 'reporting',
            value: v => v.reporting,
          },
          {
            label: 'Scope 3',
            key: 'scope3',
            value: v => v.scope3 && v.scope3.name,
          },
          {
            label: 'BCU registration date',
            editable: false,
            value: v =>
              formatDate(
                v.registrationApproveDate,
                DATE_WITH_TIME_FORMAT,
                false,
                '',
              ),
          },
          {
            label: 'BCU expiration date',
            editable: false,
            value: v =>
              formatDate(v.statusExpiration, DATE_WITH_TIME_FORMAT, false, ''),
          },
          {
            label: 'BCU status',
            key: 'status',
            editable: true,
            type: 'select',
            options: () => STATUSES.slice(1),
            render: v => <StatusComponent statusOfComponent={v.status} />,
            value: v => v.status,
          },
        ],
      },
    ];
  }

  const fields: { fields: DataKeyType[]; label?: string | ReactNode }[] = [
    {
      label: '1. General information',
      fields: [
        {
          label: 'BCU ID',
          type: 'text',
          editable: false,
          value: (v: any) => v?.BCUID,
        },
        {
          label: 'End product type',
          key: 'productType',
          type: 'select',
          options: () => {
            return PRODUCT_TYPES;
          },
          editable: true,
          value: (v: any) => v.productType,
        },
        {
          label: 'Sustainability certification',
          key: 'certificationScheme',
          type: 'select',
          editable: true,
          options: () => baselineOptions,
          value: (v: any) => v.certificationScheme,
          render: (v: any) => v.abatementBaseline?.name || v.certificationScheme || '',
        },
        {
          label: 'Sustainability certification number',
          editable: true,
          type: 'text',
          key: 'sustainabilityCertificationNumber',
          value: (v: any) => v.sustainabilityCertificationNumber,
        },
        {
          label: 'Certification body',
          editable: true,
          type: 'text',
          key: 'certificationBody',
          value: (v: any) => v.certificationBody,
        },
        {
          label: 'Feedstock certification',
          key: 'feedstockCertification',
          editable: true,
          type: 'select',
          options: () => feedstockCertsOptions,
          value: (v: any) => v.feedstockCertification,
          render: (v: any) =>
            feedstockCertsOptions.find(
              o => o.value === v.feedstockCertification,
            )?.label || '',
        },
        {
          label: 'Feedstock conversion process',
          key: 'feedstockConversionProcess',
          editable: true,
          type: 'select',
          options: () => CONVERSION_PROCESSES,
          value: (v: any) => v.feedstockConversionProcess,
          render: (v: any) =>
            CONVERSION_PROCESSES.find(
              o => o.value === v.feedstockConversionProcess,
            )?.label || '',
        },
        {
          label: 'Raw material (feedstock)',
          key: 'rawMaterial',
          editable: true,
          type: 'select',
          options: () => RAW_MATERIALS,
          value: (v: any) => v.rawMaterial,
          render: (v: any) =>
            RAW_MATERIALS.find(o => o.value === v.rawMaterial)?.label || '',
        },
        {
          label: 'Country of feedstock origin',
          editable: true,
          type: 'select',
          options: () => COUNTRIES,
          key: 'feedstockOriginWithSCSDetail',
          value: (v: any) => v.feedstockOriginWithSCSDetail,
        },
        {
          label: 'Lower heating value',
          key: 'lowerHeatingValue',
          editable: true,
          type: 'number',
          value: (v: any) => limitOfNumbers(v.lowerHeatingValue || 0, 3),
        },
        {
          label: 'PoS number of end product',
          key: 'originalPOSNumber',
          type: 'numberAndSlashes',
          editable: true,
          value: (v: any) => v.originalPOSNumber,
        },
        {
          label: 'PoS of end product issue date',
          key: 'posIssueDate',
          type: 'date',
          editable: true,
          value: (v: any) => v.posIssueDate,
          render: (v: any) => formatDate(v.posIssueDate, 'dd.MM.yyyy', true),
        },
        {
          label: 'PoS pdf upload of end product',
          key: 'posFiles',
          value: (v: any) => v.posFiles,
          render: (v: any) => {
            const files = (v?.posFiles || []).map((f: any) => ({
              ...f.attachment,
              uid: f.attachment?.id?.toString(),
            }));
            return (
              <Attachment
                accept="application/pdf"
                readonly={!editable}
                files={files}
                handleUpload={handleUploadFile('posFiles', 'posFiles')}
                handleOpen={handleOpenFile}
                handleRemove={handleRemoveFile('posFiles')}
              />
            );
          },
        },
      ],
    },
    {
      label: '2. Site information',
      fields: [
        {
          label: 'Name',
          key: 'supplierName',
          type: 'text',
          editable: true,
          value: (v: any) => v.supplierName,
        },
        {
          label: 'Street 1',
          key: 'supplierAddress',
          type: 'text',
          editable: true,
          value: (v: any) => v.supplierAddress,
        },
        {
          label: 'Street 2',
          key: 'supplierAddressNumber',
          type: 'text',
          editable: true,
          value: (v: any) => v.supplierAddressNumber,
        },
        {
          label: 'Post code',
          key: 'supplierZip',
          type: 'text',
          editable: true,
          value: (v: any) => v.supplierZip,
        },
        {
          label: 'City',
          key: 'supplierCity',
          type: 'text',
          editable: true,
          value: (v: any) => v.supplierCity,
        },
        {
          label: 'Country',
          key: 'deliveryCountry',
          type: 'select',
          options: () => COUNTRIES,
          editable: true,
          value: v => v.deliveryCountry,
        },
        {
          label: 'Does a third-party manage the site?',
          key: 'companyName',
          editable: false,
          value: (v: any) => v.companyName ? 'Yes' : 'No',
        },
        {
          label: 'Company name',
          key: 'companyName',
          type: 'text',
          editable: true,
          value: (v: any) => v.companyName,
        },
        {
          label: 'Chain of custody model',
          key: 'chainCustodyModel',
          type: 'select',
          editable: true,
          options: () => CHAIN_OF_CUSTODY_MODEL,
          value: (v: any) => v.chainCustodyModel,
          render: (v: any) =>
            CHAIN_OF_CUSTODY_MODEL.find(o => o.value === v.chainCustodyModel)
              ?.label || '',
        },
      ],
    },
    {
      label: `3. Product information ${data.neatProductSource === "Supplier" ? "(Supplier Information)" : "(Final Production Site Information)"}`,
      fields: data.neatProductSource === "Supplier" 
        ? [
          {
            label: 'End product',
            key: 'endProduct',
            type: 'select',
            editable: true,
            options: () => END_PRODUCT_INFO,
            value: (v: any) => v.endProduct || '',
          },
          {
            label: 'Product source',
            key: 'productSource',
            editable: false,
            // type: 'select',
            // options: () => NEAT_PRODUCT_SOURCE_SUMMARY,
            value: (v: any) => v.neatProductSource 
              ? v.neatProductSource === 'Supplier'
                ? 'Acquired'
                : 'Produced'
              : '',
          },
          {
            label: 'PoS provided by supplier ID number',
            key: 'posID',
            type: 'text',
            editable: true,
            value: (v: any) => v.posID || '',
          },
          {
            label: 'PoS provided by supplier issue date',
            key: 'supPosIssueDate',
            type: 'date',
            editable: true,
            value: (v: any) => v.supPosIssueDate || '',
            render: (v: any) => formatDate(v.supPosIssueDate, 'dd.MM.yyyy', true),
          },
          {
            label: 'PoS provided by supplier pdf upload',
            key: 'supplierPOSFiles',
            value: (v: any) => v.supplierFiles,
            render: (v: any) => {
              const files = (v?.supplierFiles || []).map((f: any) => ({
                ...f.attachment,
                uid: f.attachment?.id?.toString(),
              }));
              return (
                <Attachment
                  accept="application/pdf"
                  readonly={!editable}
                  files={files}
                  handleUpload={handleUploadFile('supplierFiles', 'supplierFiles')}
                  handleOpen={handleOpenFile}
                  handleRemove={handleRemoveFile('supplierFiles')}
                />
              );
            },
          },
          {
            label: 'Name',
            key: 'blendingName',
            type: 'text',
            editable: true,
            value: (v: any) => v.blendingName || '',
          },
          {
            label: 'Street 1',
            key: 'blendingAddress',
            type: 'text',
            editable: true,
            value: (v: any) => v.blendingAddress || '',
          },
          {
            label: 'Street 2',
            key: 'blendingAddressNumber',
            type: 'text',
            editable: true,
            value: (v: any) => v.blendingAddressNumber || '',
          },
          {
            label: 'Post code',
            key: 'blendingZip',
            type: 'text',
            editable: true,
            value: (v: any) => v.blendingZip || '',
          },
          {
            label: 'City',
            key: 'blendingCity',
            type: 'text',
            editable: true,
            value: (v: any) => v.blendingCity || '',
          },
          {
            label: 'Country',
            key: 'blendingCountry',
            type: 'select',
            options: () => COUNTRIES,
            editable: true,
            value: (v: any) => v.blendingCountry || '',
          },
        ] : [
          {
            label: 'End product',
            key: 'endProduct',
            type: 'select',
            editable: true,
            options: () => END_PRODUCT_INFO,
            value: (v: any) => v.endProduct || '',
          },
          {
            label: 'Product source',
            key: 'productSource',
            editable: false,
            // type: 'select',
            // options: () => NEAT_PRODUCT_SOURCE_SUMMARY,
            value: (v: any) => v.neatProductSource 
              ? v.neatProductSource === 'Supplier'
                ? 'Acquired'
                : 'Produced'
              : '',
          },
          {
            label: 'Name',
            key: 'blendingName',
            type: 'text',
            editable: true,
            value: (v: any) => v.blendingName || '',
          },
          {
            label: 'Street 1',
            key: 'blendingAddress',
            type: 'text',
            editable: true,
            value: (v: any) => v.blendingAddress || '',
          },
          {
            label: 'Street 2',
            key: 'blendingAddressNumber',
            type: 'text',
            editable: true,
            value: (v: any) => v.blendingAddressNumber || '',
          },
          {
            label: 'Post code',
            key: 'blendingZip',
            type: 'text',
            editable: true,
            value: (v: any) => v.blendingZip || '',
          },
          {
            label: 'City',
            key: 'blendingCity',
            type: 'text',
            editable: true,
            value: (v: any) => v.blendingCity || '',
          },
          {
            label: 'Country',
            key: 'blendingCountry',
            type: 'select',
            options: () => COUNTRIES,
            editable: true,
            value: (v: any) => v.blendingCountry || '',
          },
        ]
    },
    {
      label: '4. Batch information',
      fields: [
        {
          label: '',
          key: 'batches',
          value: () => null,
          render: () =>
            data.batches && (
              <BatchData
                batches={data.batches}
                endProduct={data.endProduct}
                productSource={data.neatProductSource}
                editable={false}
                baselineValue={baselineValue}
              />
            ),
          renderEdit: (v: any, onChange: (value: any) => any) =>
            data.batches && (
              <BatchData
                batches={data.batches}
                endProduct={data.endProduct}
                editable={true}
                baselineValue={baselineValue}
                onChange={batches => {
                  onChange(batches);
                }}
              />
            ),
        },
        {
          label: 'Country of physical end product delivery',
          key: 'countryOfPhysicalEndProductDelivery',
          type: 'select',
          options: () => COUNTRIES,
          editable: true,
          value: (v: any) => v.countryOfPhysicalEndProductDelivery,
        },
        {
          label: 'Airport of physical end product delivery',
          key: 'airportOfPhysicalEndProductDelivery',
          editable: true,
          value: (v: any) =>
            v?.airportOfPhysicalEndProductDeliveryData
              ? `${v?.airportOfPhysicalEndProductDeliveryData?.name || ''} ${
                  v?.airportOfPhysicalEndProductDeliveryData?.code || ''
                } ${v?.airportOfPhysicalEndProductDeliveryData?.country || ''}`
              : v.airportOfPhysicalEndProductDelivery,
          renderEdit: (v: any, onChange: any) => {
            return (
              <SearchAirportInput
                className="c-data-table__input"
                country={v.countryOfPhysicalEndProductDelivery}
                value={v.airportOfPhysicalEndProductDelivery}
                onChange={(v: any) => onChange(v)}
              />
            );
          },
        },
        {
          label:
            'Additional claim as allowed under the RSB certification system',
          key: 'additionalClaimAsAllowedUnderRsb',
          type: 'text',
          editable: true,
          value: (v: any) => v.additionalClaimAsAllowedUnderRsb,
        },
        {
          label: 'Declaration of incentives and tax credits',
          key: 'incentivesSAFDeclarationAndTaxCredits',
          editable: true,
          options: () => INCENTIVES_TAX_CREDITS,
          value: (v: any) => v.incentivesSAFDeclarationAndTaxCredits?.replace(/,(\S)/g, ', $1'),
          renderEdit: (v: any, onChange: any) => {
            return (
              <Select
                className="c-data-table__input"
                showSearch={true}
                mode="multiple"
                options={INCENTIVES_TAX_CREDITS}
                value={(typeof v.incentivesSAFDeclarationAndTaxCredits ===
                'string'
                  ? v.incentivesSAFDeclarationAndTaxCredits
                  : ''
                )
                  .split(',')
                  .map((v: string) => v.trim())}
                onChange={(v: any) =>
                  onChange(Array.isArray(v) ? v.join(', ') : '')
                }
              />
            );
          },
        },
      ],
    },
    {
      label: '5. ICAO CORSIA information',
      fields: [
        // {
        //   label: 'Production date of Neat SAF',
        //   key: 'neatSAFProductionDate',
        //   type: 'date',
        //   editable: true,
        //   value: v => v.neatSAFProductionDate,
        //   render: v => formatDate(v.neatSAFProductionDate, 'dd.MM.yyyy', true),
        // },
        {
          label: 'Default ILUC value (gCO2eg/MJ)',
          key: 'defaultILUCValue',
          type: 'text',
          editable: true,
          value: (v: any) => limitOfNumbers(v.defaultILUCValue || 0, 3),
        },
        {
          label:
            'If applicable, disaggregated value for direct land use charge',
          key: 'directLandUseChangeDisaggregatedValue',
          type: 'text',
          editable: true,
          value: (v: any) => limitOfNumbers(v.directLandUseChangeDisaggregatedValue || 0, 3),
        },
        {
          label:
            'If applicable, disaggregated value for landfill emission credit',
          key: 'landfillDirectionCreditDisaggregatedValue',
          type: 'text',
          editable: true,
          value: (v: any) => limitOfNumbers(v.landfillDirectionCreditDisaggregatedValue || 0, 3),
        },
        {
          label:
            'If applicable, disaggregated value for recycling emission credit (REC)',
          key: 'recyclingEmissionCreditDisaggregatedValue',
          type: 'text',
          editable: true,
          value: (v: any) => limitOfNumbers(v.recyclingEmissionCreditDisaggregatedValue || 0, 3),
        },
      ],
    },
    {
      label: `${isCorsiaStepExist ? 6 : 5}. Total values`,
      fields: [
        {
          label: (
            <span>
              Final LCA GHG value (gCO<sub>2</sub>eq/MJ)
            </span>
          ),
          key: 'LCAGHGValue',
          type: 'number',
          editable: true,
          value: (v: any) => limitOfNumbers(v.LCAGHGValue || '0', 2),
        },
        {
          label: 'Final GHG emission reduction (weighted, %)',
          key: 'GHGEmissionReduction',
          type: 'number',
          editable: true,
          value: (v: any) => limitOfNumbers(v.GHGEmissionReduction || '0', 3),
        },
        {
          label: 'Neat amount in MJ',
          key: 'neatAmountInMJ',
          type: 'number',
          editable: true,
          value: (v: any) => limitOfNumbers(v.neatAmountInMJ || '0', 0),
        },
        {
          label: (
            <span>
              Reference fossil baseline (gCO<sub>2</sub>eq/MJ)
            </span>
          ),
          key: 'abatementBaselineScheme',
          editable: false,
          value: (v: any) =>
            limitOfNumbers(v.abatementBaseline?.baselineValue || '0', 2),
        },
        {
          label: 'Total neat amount (t)',
          key: 'volume',
          type: 'number',
          editable: true,
          value: (v: any) => limitOfNumbers(v.volume || '0', 3),
        },
        {
          label: (
            <span>
              Total CO<sub>2</sub>eq reduction (t)
            </span>
          ),
          key: 'tonnesOfCO2eq',
          type: 'number',
          editable: true,
          value: (v: any) => limitOfNumbers(v.tonnesOfCO2eq || '0', 3),
        },
      ],
    },
    {
      label: `${isCorsiaStepExist ? 7 : 6}. Other`,
      fields: [
        {
          label: 'BCU registration date',
          editable: false,
          value: (v: any) =>
            formatDate(
              v.registrationApproveDate,
              DATE_WITH_TIME_FORMAT,
              false,
              '',
            ),
        },
        {
          label: 'BCU expiration date',
          editable: false,
          value: (v: any) =>
            formatDate(v.statusExpiration, DATE_WITH_TIME_FORMAT, false, ''),
        },
        {
          label: 'BCU status',
          key: 'status',
          type: 'select',
          editable: true,
          options: () => STATUSES.slice(1),
          render: (v: any) => <StatusComponent statusOfComponent={v.status} />,
          value: (v: any) => v.status,
        },
      ],
    },
  ];

  const filteredFields = fields.map((field: any)  => {
    return { 
      ...field, 
      fields: field.fields.filter((f: any) => {
        if (f.key === 'additionalClaimAsAllowedUnderRsb') {
          return !!f.value(data);
        }
        if (f.key === 'companyName') {
          return !!f.value(data);
        }
        return true;
      })
    } 
  });

  const filteredFieldsCorsia = filteredFields.filter((field: any) => {
    return !(!isCorsiaStepExist && field.label === "5. ICAO CORSIA information");
  });

  return filteredFieldsCorsia;
};

const BCUData: FC<{
  data: any;
  user?: Prisma.UserGetPayload<{ include: { company: true } }>;
  editable?: boolean;
  onSave?: (data: any) => Promise<boolean>;
  onDownloadPosFile?: () => void;
  onDownloadSupplierFile?: () => void;
  keysFn?: (
    data: any,
    baselines: AbatementBaseline[],
    editable: boolean,
  ) => { fields: DataKeyType[]; label?: string | ReactNode }[];
}> = ({ data, user, editable, onSave, keysFn }) => {
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const [groups, setGroups] = useState<
    Array<{
      label?: string | ReactNode;
      fields: DataKeyType[];
      processedFields: ProcessedDataKeyType[];
    }>
  >([]);
  const [isEdit, setIsEdit] = useState(false);
  const [innerData, setInnerData] = useState(
    JSON.parse(JSON.stringify(data || {})),
  );
  const [preSaveData, setPreSaveData] = useState(
    JSON.parse(JSON.stringify(data || {})),
  );
  const { data: baselines } = useGetAbatementBaselinesQuery();

  useEffect(() => {
    dispatch(fetchFeedstockCerts());
  }, []);

  const feedstockCerts = useAppSelector(selectFeedstockCerts);

  const feedstockCertsOptions = useMemo(
    () =>
      (feedstockCerts || []).map(f => ({
        label: f.name,
        value: f.name,
      })),
    [feedstockCerts],
  );

  const handleFieldChange = (field: ProcessedDataKeyType, value: any) => {
    if (typeof field.key !== 'undefined') {
      setInnerData((prevData: any) => ({
        ...prevData,
        [field.key as string]: value,
      }));
    } else {
      setInnerData((prevData: any) => ({
        ...prevData,
        ...value,
      }));
    }
  };

  const handleUploadFile =
    (kind: string, key: string) => async (file: RcFile) => {
      if (!editable) {
        return false;
      }

      const formData = new FormData();

      formData.set('file', file as any);

      return dispatch(
        addAttachment(formData, RelationTypes.CREDIT, kind, innerData.id),
      ).then(
        (response: any) => {
          const attachment = response?.data?.data;
          const attachmentEntity = {
            entityId: innerData.id,
            attachmentId: attachment?.id,
            attachment,
          };

          // add file to the list
          handleFieldChange({ key } as ProcessedDataKeyType, [
            ...innerData[key],
            attachmentEntity,
          ]);
          setPreSaveData((prevData: any) => ({
            ...prevData,
            [key]: [...prevData[key], attachmentEntity],
          }));

          return attachment;
        },
        () => false,
      );
    };

  const handleOpenFile = async (file: UploadAttachment) => {
    return dispatch(downloadAttachment(file.id) as any)
      .then((response: any) => {
        fileDownload(response.data, file.name);
      })
      .catch((e: any) => {
        toast.error({ message: 'Error', description: e.response.data.message });
      });
  };

  const handleRemoveFile = (key: string) => async (file: UploadAttachment) => {
    if (!editable) {
      return false;
    }

    return dispatch(removeAttachment(file.id)).then(
      () => {
        // remove file from the list
        handleFieldChange(
          { key } as ProcessedDataKeyType,
          innerData[key].filter((f: any) => f.attachmentId !== file.id),
        );
        setPreSaveData((prevData: any) => ({
          ...prevData,
          [key]: prevData[key].filter((f: any) => f.attachmentId !== file.id),
        }));

        return true;
      },
      () => false,
    );
  };

  useEffect(() => {
    const d = JSON.parse(JSON.stringify(data));

    setInnerData(d);
    setPreSaveData(d);
  }, [data]);

  useEffect(() => {
    const userCompany = user?.company;
    
    const isFromThisSupplier =
      userCompany &&
      userCompany.type === CompanyType.Supplier &&
      innerData.company &&
      innerData.companyId === user!.companyId;

    const isFacilitator = userCompany?.type === CompanyType.Facilitator;

    const isSuperUser = isInTypes(user?.role, [
      UserRole.Auditor,
      UserRole.Admin,
      UserRole.SuperAdmin,
    ]);

    setGroups(
      (keysFn
        ? keysFn(innerData, baselines, editable || false)
        : getBCUKeys(
            innerData,
            isFromThisSupplier || isSuperUser || isFacilitator,
            handleUploadFile,
            handleOpenFile,
            handleRemoveFile,
            baselines,
            editable,
            feedstockCertsOptions,
          )
      ).map(keys => {
        const processedFields = (keys.fields || []).map(field => ({
          ...field,
          options: field.options && field.options(innerData),
          value: field.value(innerData),
          ...(field.render ? { render: field.render(innerData) } : {}),
        }));

        return {
          ...keys,
          processedFields: processedFields.map(processedField => ({
            ...processedField,
            ...(processedField.renderEdit
              ? {
                  renderEdit: processedField.renderEdit(innerData, v =>
                    handleFieldChange(processedField, v),
                  ),
                }
              : {}),
          })),
        };
      }),
    );
  }, [innerData, user, baselines, editable]);

  const handleEditCancel = () => {
    setInnerData(JSON.parse(JSON.stringify(preSaveData)));
    setIsEdit(false);
  };

  const handleEditSave = async () => {
    if (!onSave) {
      return true;
    }

    const differences: any = diff(preSaveData, innerData);

    if (differences.batches && innerData.batches) {
      differences.batches = innerData.batches;
    }

    // No changes made
    if (Object.keys(differences).length === 0) {
      setIsEdit(false);
      return true;
    }

    // Set undefined values to null to avoid issues with the backend
    Object.keys(differences).forEach(key => {
      if (typeof differences[key] === 'undefined') {
        differences[key] = null;
      }
    });

    return onSave({
      ...differences,
      id: data.id,
    }).then(isSaveSuccessful => {
      if (isSaveSuccessful) {
        setIsEdit(false);

        setPreSaveData({
          ...innerData,
          ...differences,
        });
      }
      return isSaveSuccessful;
    });
  };

  return (
    <div className="c-bcu-data">
      <div className="c-bcu-data__wrapper">
        <DataTable
          data={innerData}
          edit={isEdit}
          columns={groups}
          onChange={handleFieldChange}
        />
        {editable && (
          <EditButtons
            isEdit={isEdit}
            onEdit={() => setIsEdit(true)}
            onSave={handleEditSave}
            onCancel={handleEditCancel}
          />
        )}
      </div>
    </div>
  );
};

export default BCUData;
