import {
  RawMaterial,
  ProductType,
  ChainCustodyModel,
  ConversionProcess,
} from '@prisma/client';

import { toOptions } from '../../../../../helpers/toOptions';

export const RAW_MATERIALS = [
  { value: RawMaterial.AgriculturalResidues, label: 'Agricultural Residues' },
  { value: RawMaterial.BrassicaCarinataOil, label: 'Brassica Carinata Oil' },
  { value: RawMaterial.CamelinaOil, label: 'Camelina Oil' },
  { value: RawMaterial.CornGrain, label: 'Corn Grain' },
  { value: RawMaterial.CornOil, label: 'Corn Oil' },
  { value: RawMaterial.ForestryResidues, label: 'Forestry Residues' },
  {
    value: RawMaterial.JatrophaOilDetoxific,
    label: 'Jatropha Oil (meal used as animal feed after detoxification)',
  },
  {
    value: RawMaterial.JatrophaOilElectric,
    label: 'Jatropha Oil (meal used as fertilizer or electricity input)',
  },
  {
    value: RawMaterial.Miscanthus,
    label: 'Miscanthus (herbaceous energy crops)',
  },
  { value: RawMaterial.MSW, label: 'Municipal Solid Waste (MSW)' },
  {
    value: RawMaterial.PalmFattyAcidDistillate,
    label: 'Palm Fatty Acid Distillate',
  },
  { value: RawMaterial.PalmOil, label: 'Palm Oil' },
  { value: RawMaterial.Poplar, label: 'Poplar (short-rotation woody crops)' },
  { value: RawMaterial.RapeseedOil, label: 'Rapeseed Oil' },
  { value: RawMaterial.SoybeanOil, label: 'Soybean Oil' },
  { value: RawMaterial.SugarBeet, label: 'Sugar Beet' },
  { value: RawMaterial.Sugarcane, label: 'Sugarcane' },
  {
    value: RawMaterial.Switchgrass,
    label: 'Switchgrass (herbaceous energy crops)',
  },
  { value: RawMaterial.Tallow, label: 'Tallow' },
  { value: RawMaterial.UsedCookingOil, label: 'Used Cooking Oil' },
  { value: RawMaterial.WasteGasesEthanol, label: 'Waste Gases Ethanol' },
];

export const PRODUCT_TYPES = [{ value: ProductType.SAF, label: 'SAF' }];

export const CUSTODY_MODELS = [
  { value: ChainCustodyModel.MassBalance, label: 'Mass Balance' },
];

export const CERTIFICATION_SCHEMES = [
  { value: 'RSB_Global', label: 'RSB Global' },
  { value: 'RSB_EU_Red', label: 'RSB EU Red' },
  { value: 'RSB_CORSIA', label: 'RSB CORSIA' },
  { value: 'EU_Red', label: 'EU Red' },
  { value: 'EU_Red2', label: 'EU Red 2' },
  { value: 'ICAO_CORSIA', label: 'ICAO CORSIA' },
];

export const SUSTAINABILITY_CERTIFICATION = toOptions([
  'RSB Global',
  'RSB EU RED',
  'RSB CORSIA',
  'ISCC EU RED',
  'ISCC CORSIA',
]);

export const CHAIN_OF_CUSTODY_MODEL = [
  { value: ChainCustodyModel.MassBalance, label: 'Mass Balance' },
];

export const END_PRODUCT = [{ value: ProductType.SAF, label: 'SAF' }];

export const REPORTING = toOptions([
  'UK ETS (Scope 1)',
  'EU ETS (Scope 1)',
  'CORSIA (Scope 1)',
  'EU RED II (Scope 1)',
  'Voluntary (Scope 1 + 3)',
]);

export const NEAT_PRODUCT_SOURCE = toOptions(['Supplier', 'Self-production']);

export const NEAT_PRODUCT_SOURCE_SUMMARY = toOptions(['Acquired', 'Produced']);

export const FEEDSTOCK_CERTIFICATION = toOptions([
  'Production residue',
  'End-of-life product',
]);

export const END_PRODUCT_INFO = toOptions([
  'Blended',
  'Unblended',
  'Co-processed',
]);

export const COUNTRY = toOptions([
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Australia',
  'Austria',
  'Anguilla',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Costa Rica',
  "Cote d'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  "Democratic People's Republic of Korea",
  'Democratic Republic of Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Egypt',
  'El Salvador',
  'Equatorial New Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'European Union',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  "Lao People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritius',
  'Mauritania',
  'Mexico',
  'Micronesia',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Niger',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Republic of Korea',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'State of Palestine',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Tajikistan',
  'Thailand',
  'The Former Yugoslav Republic of Macedonia',
  'Timor-Leste',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United Republic of Tanzania',
  'United States of America',
  'Uruguay',
  'Vanuatu',
  'Venezuela',
  'Viet Nam',
  'Zimbabwe',
]);

export const CONVERSION_PROCESSES = [
  { value: ConversionProcess.FT, label: 'FT' },
  { value: ConversionProcess.HEFA, label: 'HEFA' },
  { value: ConversionProcess.SIP, label: 'SIP' },
  { value: ConversionProcess.FT_SKA, label: 'FT-SKA' },
  { value: ConversionProcess.ATJ_SPK, label: 'ATJ-SPK' },
  { value: ConversionProcess.CHJ, label: 'CHJ' },
  { value: ConversionProcess.HC_HEFA_SPK, label: 'HC-HEFA-SPK' },
];

export const INCENTIVES_TAX_CREDITS = toOptions([
  'California LCFS',
  'US RFS (RIN)',
  'US IRS BTC',
  'NL HBE Scheme',
]);
