import { FormikErrors } from 'formik';
import { MyBatchFormValue } from './types';

export const validateBatchForm = (
  values: MyBatchFormValue,
  setErrors?: (errors: FormikErrors<MyBatchFormValue>) => void,
  isDateBlendAvailable?: boolean,
  airportOptions?: any,
  isSecondAttempt?: boolean,
  shouldValidate?: boolean,
) => {
  const errors: Partial<MyBatchFormValue> = { batchInformation: [] };

  values.batchInformation.forEach((batch, index) => {
    const batchErrors: Partial<typeof batch> = {};
    const matchingAirport = airportOptions ? airportOptions.find(
      (airport: { value: string; label: string }) => airport.value === batch.fieldSaf
    ) : true;

    const isAnotherBatchFiledIdError =
      values.batchInformation
        .map(batch => batch.fieldID)
        .filter(batchId => batchId === batch.fieldID).length > 1;

    
    if (!batch.fieldID) {
      batchErrors.fieldID = 'Field is required';
    }

    if (isAnotherBatchFiledIdError) {
      batchErrors.fieldID = 'This batch ID cannot be the same';
    }

    if (!batch.fieldTonnes) {
      batchErrors.fieldTonnes = 'Field is required';
    }

    if (!batch.fieldMJ) {
      batchErrors.fieldMJ = 'Field is required';
    }

    if (!batch.dateProd) {
      batchErrors.dateProd = 'Field is required';
    }

    if (!batch.dateBlend && isDateBlendAvailable) {
      batchErrors.dateBlend = 'Field is required';
    }

    if (!batch.entryBlendDate) {
      batchErrors.entryBlendDate = 'Field is required';
    }

    if (!batch.productDeliveryDate) {
      batchErrors.productDeliveryDate = 'Field is required';
    }

    if (!batch.fieldCO2) {
      batchErrors.fieldCO2 = 'Field is required';
    }

    if (!batch.fieldBatchCountryOf) {
      batchErrors.fieldBatchCountryOf = 'Field is required';
    }

    // @see RBCRB-370 - Set fieldSaf as optional
    if (batch.fieldSaf && !batch.fieldBatchCountryOf) {
      batchErrors.fieldSaf = 'Country of end product delivery is required for this field';
    }

    if (!matchingAirport && batch.fieldSaf && shouldValidate && !isSecondAttempt) {
      if (airportOptions.length) {
        batchErrors.fieldSaf = 'Airport not found';
      } else {
        batchErrors.fieldSaf = 'No airports available for the selected country';
      }
    }

    if (Object.keys(batchErrors).length > 0) {
      (errors.batchInformation as Array<Partial<typeof batch>>)[index] =
        batchErrors;
    }  
  });

  const fieldIDCounts = values.batchInformation.reduce((acc, batch) => {
    acc[batch.fieldID] = (acc[batch.fieldID] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  values.batchInformation.forEach((batch, index) => {
    if (fieldIDCounts[batch.fieldID] > 1) {
      (errors.batchInformation as Array<Partial<typeof batch>>)[index] = {
        ...((errors.batchInformation as Array<Partial<typeof batch>>)[index] || {}),
        fieldID: 'This batch ID must be unique',
      };
    }
  });
  
  setErrors && setErrors(errors);

  return errors;
};
