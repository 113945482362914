import React, {
  FC,
  ChangeEvent,
  FocusEvent,
  DragEvent,
  useState,
  useRef,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import './PdfFileUpload.scss';
import FileUploadComponent from '../FileUploadComponent';
import {
  setFileData,
  setFileDataTwoStep,
  setFileUploaded,
  setFileUploadedTwoStep,
} from '../../../../../store/reducers/user/bcuFormReducer';
import { RootState } from '../../../../../store/store';
import closeRemovePdf from '../../../../../assets/closeRemovePdf.svg';
import classNames from 'classnames';

interface IPdfFileUploadProps {
  id: string;
  name: string;
  label: string;
  required?: boolean;
  errorVisible?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  error: string | undefined;
  onFileUpload: (file: File) => void;
  onDragAndDrop: (file: File | null) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  disabled?: boolean;
}

const PdfFileUpload: FC<IPdfFileUploadProps> = ({
  id,
  label,
  name,
  required,
  onBlur,
  error,
  onFileUpload,
  onDragAndDrop,
  setFieldValue,
  errorVisible,
  disabled = false,
}) => {
  const dispatch = useAppDispatch();
  const [isDragActive, setIsDragActive] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const file = useAppSelector((state: RootState) => state.form.fileData);
  const fileTwo = useAppSelector(
    (state: RootState) => state.form.fileDataTwoStep,
  );

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragActive(false);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleRemoveFile = () => {
    setFieldValue(name, '');
    dispatch(setFileData(''));
    dispatch(setFileUploaded(false));
    onDragAndDrop(null);
  };

  const handleRemoveFileTwoStep = () => {
    setFieldValue(name, '');
    dispatch(setFileDataTwoStep(''));
    dispatch(setFileUploadedTwoStep(false));
    onDragAndDrop(null);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      if (selectedFile.type !== 'application/pdf') {
        dispatch(setFileData(''));
        setFieldValue(name, '');
        dispatch(setFileUploaded(false));
        onDragAndDrop(null);
        return;
      }
      onDragAndDrop(selectedFile);
      if (label === 'PoS pdf upload of end product') {
        dispatch(setFileData(selectedFile.name));
        setFieldValue(name, selectedFile.name);
        dispatch(setFileUploaded(true));
      } else if (label === 'PoS pdf upload') {
        setFieldValue(name, selectedFile.name);
        dispatch(setFileDataTwoStep(selectedFile.name));
        dispatch(setFileUploadedTwoStep(true));
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragActive(false);
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile) {
      if (selectedFile.type !== 'application/pdf') {
        dispatch(setFileData(''));
        setFieldValue(name, '');
        dispatch(setFileUploaded(false));
        onDragAndDrop(null);
      } else {
        const file: File = new File([selectedFile], selectedFile.name, {
          type: selectedFile.type,
        });
        onDragAndDrop(file);
        if (label === 'PoS pdf upload of end product') {
          setFieldValue(name, file.name);
          dispatch(setFileData(file.name));
          dispatch(setFileUploaded(true));
        } else if (label === 'PoS pdf upload') {
          setFieldValue(name, file.name);
          dispatch(setFileDataTwoStep(file.name));
          dispatch(setFileUploadedTwoStep(true));
        }
      }
    }
  };

  const renderUploadedFileName = () => {
    if (label === 'PoS pdf upload of end product') {
      return file;
    } else if (label === 'PoS pdf upload') {
      return fileTwo;
    }
  };

  return (
    <div
      className={`pdf-uploader--container ${isDragActive ? 'drag-active' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}>
      <label className="pdf-uploader--label" htmlFor={id}>
        {label}
        {required && <span className="pdf-uploader--required">*</span>}
      </label>
      <div
        className={classNames('pdf-uploader--border', {
          error: !!error && errorVisible,
        })}>
        <div className="pdf-uploader--field">
          <input
            id={id}
            name={name}
            type="file"
            accept="application/pdf"
            onBlur={onBlur}
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          {file && name === 'fieldUpDateProd' ? (
            <div className="drop-panel">
              <p className="drop-p">{renderUploadedFileName()}</p>
              <img
                className="close_remove_pdf"
                src={closeRemovePdf}
                alt="Close Remove PDF"
                onClick={handleRemoveFile}
              />
            </div>
          ) : fileTwo && name === 'fieldUpDate' ? (
            <div className="drop-panel">
              <p className="drop-p">{renderUploadedFileName()}</p>
              <img
                className="close_remove_pdf"
                src={closeRemovePdf}
                alt="Close Remove PDF"
                onClick={handleRemoveFileTwoStep}
              />
            </div>
          ) : (
            <>
              <div className="drag-drop-label">
                <span className="drag-drop-text">
                  Drag and drop file here or
                </span>
              </div>
              <FileUploadComponent
                onFileUpload={onFileUpload}
                label={label}
                disabled={disabled}
              />
            </>
          )}
        </div>
      </div>
      {error && errorVisible && <span className="field-errorUp">{error}</span>}
    </div>
  );
};

export default PdfFileUpload;
