// LOGIN
export const URL_LOGIN = 'auth/login';
export const URL_LOGOUT = 'auth/logout';
export const URL_GET_USER = 'auth';
export const URL_REFRESH_TOKEN = 'auth/refresh';
export const URL_VALIDATE_INVITE_TOKEN = 'auth/validate';

export const URL_GENERATE_TOKEN = 'share-token/generate';

export const URL_CONFIRM_EMAIL = 'email-confirmation/confirm';

// REGISTRATION
export const URL_REGISTRATION = 'auth/register';
export const URL_SET_PASSWORD = 'account/set-password';
export const URL_RESET_PASSWORD = 'account/reset-password';
export const URL_CHANGE_PASSWORD = 'account/change-password';
export const URL_FORGOT_PASSWORD = 'account/restore-password';
export const URL_UPDATE_ACCOUNT = 'account/update';
export const URL_ADD_USER_ACCOUNT = 'account/add';
export const URL_LIST_USERS_ACCOUNT = 'account/list';
export const URL_UPDATE_USER_ACCOUNT = 'account/user/update';
export const URL_DOWNLOAD_USER_TRANSACTIONS = 'account/download-transactions';

//ADMIN
export const URL_ADMIN_GET_SUBMISSIONS = 'submissions';
export const URL_ADMIN_GET_SUBMISSIONS_USERS = `${URL_ADMIN_GET_SUBMISSIONS}/user-registration`;
export const URL_ADMIN_GET_SUBMISSIONS_USER_UPDATES = `${URL_ADMIN_GET_SUBMISSIONS}/user-update`;
export const URL_ADMIN_GET_SUBMISSIONS_USER_UPDATE = `${URL_ADMIN_GET_SUBMISSIONS}/user-update/:id`;
export const URL_ADMIN_REJECT_USER_UPDATE = `${URL_ADMIN_GET_SUBMISSIONS}/review/user-update/:id/decline`;
export const URL_ADMIN_APPROVE_USER_UPDATE = `${URL_ADMIN_GET_SUBMISSIONS}/review/user-update/:id/approve`;
export const URL_ADMIN_REJECT_USER_REGISTRATION = `${URL_ADMIN_GET_SUBMISSIONS}/review/user-registration/:id/decline`;
export const URL_ADMIN_APPROVE_USER_REGISTRATION = `${URL_ADMIN_GET_SUBMISSIONS}/review/user-registration/:id/approve`;
export const URL_ADMIN_GET_COMPANY_INFO = `${URL_ADMIN_GET_SUBMISSIONS_USERS}/:id`;
export const URL_ADMIN_GET_SUBMISSIONS_TRANSACTIONS = `${URL_ADMIN_GET_SUBMISSIONS}/credit-transaction`;
export const URL_ADMIN_GET_SUBMISSIONS_TRANSACTION = `${URL_ADMIN_GET_SUBMISSIONS_TRANSACTIONS}/:id`;
export const URL_ADMIN_SUBMISSIONS_TRANSACTIONS_APPROVE = `${URL_ADMIN_GET_SUBMISSIONS}/review/credit-transaction/:id/approve`;
export const URL_ADMIN_SUBMISSIONS_TRANSACTIONS_DECLINE = `${URL_ADMIN_GET_SUBMISSIONS}/review/credit-transaction/:id/decline`;

//ADMIN LOGS
export const URL_ADMIN_LOGS = 'action-logs';
export const URL_ADMIN_LOGS_DOWNLOAD = `${URL_ADMIN_LOGS}/list/download`;
export const URL_ADMIN_BCU_LOGS = `${URL_ADMIN_LOGS}/credit/:id`;
export const URL_ADMIN_SUBMISSION_LOGS = `${URL_ADMIN_LOGS}/credit/submission/:id`;

//USERS
export const URL_ADMIN_GET_USERS = `/users`;
export const URL_ADMIN_GET_USER_BY_ID = `/users/:id`;
export const URL_ADMIN_EDIT_USER = `${URL_ADMIN_GET_USERS}/edit/:id`;
export const URL_ADMIN_DOWNLOAD_USERS = `${URL_ADMIN_GET_USERS}/list/download`;

//ACCOUNTS
export const URL_ADMIN_GET_ACCOUNTS = `/companies`;
export const URL_ADMIN_GET_COMPANY = `/companies/:id`;
export const URL_ADMIN_GET_COMPANY_FULL_INFO = `/companies/info/:id`;
export const URL_ADMIN_EDIT_COMPANY = `/companies/edit/:id`;
export const URL_ADMIN_FEE = `/companies/:id/fees`;
export const URL_ADMIN_EDIT_FEE = `/companies/:id/fees/:feeId`;
export const URL_ADMIN_GET_ACCOUNTS_DOWNLOAD = `/companies/download`;

//RETIREMENT_STATEMENTS
export const URL_ADMIN_GET_RETIREMENT_STATEMENTS = `retirement-statements/public-list`;
export const URL_DOWNLOAD_RETIREMENT_STATEMENT_FILE = `retirement-statements/download/:id`;

export const URL_COMPANY_DOWNLOAD_SIGNED_LETTER =
  '/companies/:id/signed-letter';

export const URL_COMPANY = '/companies/company';
// TRANSFER
export const URL_TRANSFER = 'credits/transfer/:id';
export const URL_TRANSFER_TSP_SUBMISSIONS = `${URL_ADMIN_GET_SUBMISSIONS}/tsp/credit-transaction`;
export const URL_TRANSFER_TSP_SUBMISSIONS_DETAILS = `${URL_TRANSFER_TSP_SUBMISSIONS}/:id`;
export const URL_TRANSFER_TSP_SUBMISSIONS_APPROVE = `${URL_ADMIN_GET_SUBMISSIONS}/tsp/review/credit-transaction/:id/approve`;

// RETIREMENT
export const URL_RETIREMENT = 'credits/retire/:id';

export const URL_SCOPE1_INFORMATION_REQUEST = 'credit-info/request';
export const URL_SCOPE1_INFORMATION_REQUEST_LIST = 'credit-info/list';
export const URL_SCOPE1_INFORMATION_REQUEST_COUNT = 'credit-info/count';

export const URL_SCOPE1_INFORMATION_REQUEST_UPDATE = 'credit-info/:id';

// Notes
export const URL_NOTE_GET_POST_DELETE = 'notes/:type/:id';

// Credits
export const URL_BCU_REGISTRATION = 'credits/register';
export const URL_BCU_AIRPORTS = 'airports';
export const URL_BCU_AIRPORT = 'airports/:code';
export const URL_ABATEMENT_BASELINES = 'abatement-baselines';
export const URL_FEEDSTOCK_CERTS = 'feedstock-certification'
export const URL_INCENTIVES = 'incentives';
export const URL_ABATEMENT_BASELINES_EDIT = 'abatement-baselines/:id';
export const URL_FEEDSTOCK_CERTS_EDIT = 'feedstock-certification/:id'
export const URL_INCENTIVES_EDIT = 'incentives/:id'
export const URL_COMPANY_CREDITS = 'credits';
export const URL_COMPANY_INCOMING_CREDITS = 'credit-transactions/to-approve';
export const URL_CREDITS_APPROVE = 'credit-transactions/to-approve/:id/approve';
export const URL_CREDITS_DENY = 'credit-transactions/to-approve/:id/deny';
export const URL_CREDITS_RETIRE = 'credits/retire/:id';
export const URL_BCU_FACILITATOR_UPDATE = 'credits/facilitator/:id/:action'
export const URL_REGISTRATION_POSID_EXIST = 'credits/checkPosNumber/:posNumberOfTheProduct'
export const URL_EXCEL_TEMPLATE = 'credits/excel-template/';
export const URL_EXCEL_TEMPLATE_PARSE = 'credits/parse/excel';

export const URL_ADMIN_COMPANY_CREDITS = 'credits/company/:id';
export const URL_ADMIN_BCU_LIST = 'credits/list';
export const URL__GET_ADMIN_BCU_CREDIT = 'credits/:id';
export const URL_ADMIN_BCU_EDIT = 'credits/edit/:id';
export const URL_ADMIN_SPLIT_BCU = 'credits/split/:id';
export const URL_ADMIN_BCU_LIST_DOWNLOAD = 'credits/list/download';
export const URL_POS_FILE = '/credits/download-pos/:id';
export const URL_SUPPLIER_FILE = '/credits/download-supplier/:id';

// USERS
export const URL_GET_USERS = 'URL_GET_USERS';

// Transactions

export const URL_USER_TRANSACTIONS = 'credit-transactions';

export const URL_ADMIN_TRANSACTIONS = `${URL_USER_TRANSACTIONS}/company/:id`;
export const URL_ADMIN_CREDIT_TRANSACTIONS = `${URL_USER_TRANSACTIONS}/credit/:id`;
export const URL_ADMIN_CREDIT_TRANSACTIONS_HISTORY = `${URL_USER_TRANSACTIONS}/credit/:id/history`;

// Contact us
export const URL_CONTACT_US_AUTH = 'contact-us';
export const URL_CONTACT_US = 'contact-us/public';

// Company info
export const URL_COMPANY_INFO = 'companies/info';
export const URL_COMPANY_INFO_ID = `companies/info/:id`;
export const URL_COMPANY_FACILITATORS = 'companies/:id/facilitators';

// Contacts-List
export const URL_CONTACTS_LIST_POST = '/contact-list/';
export const URL_CONTACTS_LIST_GET = '/contact-list';
export const URL_CONTACT_DELETE = `contact-list/:id`;
export const URL_CONTACT_PATCH = `contact-list/:id`;
export const URL_CONTACT_LIST_ADMIN_GET = `contact-list/:id`;

// Common data
export const URL_COMMON_COUNTRIES = 'common/countries';

export const URL_ATTACHMENTS_ADD = 'attachments/add/:type/:id/:kind';
export const URL_ATTACHMENTS_DOWNLOAD = 'attachments/download/:id';
export const URL_ATTACHMENTS_REMOVE = 'attachments/delete/:id';

// USER_BCU_LOGS
export const URL_USER_LOGS = 'action-logs';
export const URL_USER_BCU_LOGS = `${URL_USER_LOGS}/user/credit/:id`;

// FEEDBACK
export const URL_USER_FEEDBACK = 'feedback';
