import { FC, useCallback } from 'react';
import { Alert, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import SelfForm from './SelfForm';
import GeneralForm from './GeneralForm';
import BatchForm from './BatchForm';
import SustainabilityForm from './SustainabilityForm';
import BlendAccountForm from './BlendAccountForm';
import { useAppSelector } from '../../../../../store/hooks';
import { selectBaseLines } from '../../../../../store/reducers/user/abatementBaselinesReducer';
import ExcelSource from './ExcelSource';

import './BCURegistrationFormStyles.scss';

export const enum BCU_SOURCE {
  MANUAL = 'manual',
  EXCEL = 'excel',
}

interface IBCURegistrationFormProps {
  handleClick: () => void;
  step: number;
  neatProductSource: string;
  onFileUploadFirst: (file: File) => void;
  onDragAndDropFirst: (file: File | null) => void;
  onFileUploadSecond: (file: File) => void;
  onDragAndDropSecond: (file: File | null) => void;
  handleSetStep: (step: number) => void;
  fileUpload: File | null;
  dragAndDrop: File | null;
  setBatchNumber: (batchNumber: number) => void;
  batchNumber: number;
  isDisabled: boolean;
  BCUSource: BCU_SOURCE;
  setBCUSource: (source: BCU_SOURCE) => void;
}

const BCURegistrationForm: FC<IBCURegistrationFormProps> = ({
  handleClick,
  step,
  BCUSource,
  setBCUSource,
  onFileUploadFirst,
  onDragAndDropFirst,
  onFileUploadSecond,
  onDragAndDropSecond,
  handleSetStep,
  fileUpload,
  dragAndDrop,
  setBatchNumber,
  batchNumber,
  isDisabled,
}) => {
  const baselines = useAppSelector(selectBaseLines);
  const fields = useAppSelector(state => state.form.fields);
  const formValues = useAppSelector(state => state.form);
  
  const selectedBaseline = (baselines || []).find(
    b => b.id === fields.sustainabilityCertification,
  );
  const hasCORSIAStep =
    selectedBaseline &&
    selectedBaseline.name.length > 0 &&
    String(selectedBaseline.name).includes('CORSIA');

  const handleChangeBCUSource = useCallback(
    (e: RadioChangeEvent) => {
      setBCUSource(e.target.value);
    },
    [setBCUSource],
  );

  return (
    <div className="form-reg">
      <h1 className="header-form">BCU Registration</h1>
      {isDisabled && (
        <>
          <Alert
            message="This action is forbidden"
            description="Certification expired - please use the contact form for assistance."
            type="error"
          />
        </>
      )}
      {step === 1 && (
        <Radio.Group value={BCUSource} onChange={handleChangeBCUSource}>
          <Radio value={BCU_SOURCE.MANUAL} disabled={!!formValues.uploadedExcel.length}>Manual Registration</Radio>
          <Radio value={BCU_SOURCE.EXCEL} disabled={!!formValues.uploadedExcel.length}>Upload data via Excel file</Radio>
        </Radio.Group>
      )}
      {!isDisabled &&
        (BCUSource === BCU_SOURCE.MANUAL ? (
          <>
            {step === 1 && (
              <GeneralForm
                handleClick={handleClick}
                onFileUpload={onFileUploadFirst}
                onDragAndDrop={onDragAndDropFirst}
                dragAndDrop={dragAndDrop}
                fileUpload={fileUpload}
              />
            )}
            {step === 2 && <SelfForm handleClick={handleClick} />}
            {step === 3 && (
              <BlendAccountForm
                handleClick={handleClick}
                onFileUpload={onFileUploadSecond}
                onDragAndDrop={onDragAndDropSecond}
              />
            )}
            {step === 4 && (
              <BatchForm
                handleClick={handleClick}
                setBatchNumber={setBatchNumber}
                batchNumber={batchNumber}
              />
            )}
            {step === 5 && hasCORSIAStep && (
              <SustainabilityForm handleClick={handleClick} />
            )}
          </>
        ) : (
          <ExcelSource
            onFileUploadFirst={onFileUploadFirst}
            onDragAndDropFirst={onDragAndDropFirst}
            onFileUploadSecond={onFileUploadSecond}
            onDragAndDropSecond={onDragAndDropSecond}
            handleClick={handleClick}
            setBCUSource={setBCUSource}
          />
      ))}
    </div>
  );
};

export default BCURegistrationForm;
