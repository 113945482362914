import { useState } from 'react';
import { useFormik } from 'formik';
import { MyBatchFormValue } from './types';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  addBatch,
  removeBatch,
  updateBatchField,
} from '../../../../../../store/reducers/user/bcuFormReducer';
import convertToNumber from '../../../../../../helpers/convertToNumber';
import convertToString from '../../../../../../helpers/convertToString';
import { selectBaseLines } from '../../../../../../store/reducers/user/abatementBaselinesReducer';

export const useBatchForm = (setBatchNumber: (value: number) => void) => {
  const dispatch = useAppDispatch();
  const formValues = useAppSelector(state => state.form);
  const [batchIndex, setBatchIndex] = useState<number>(0);
  const baselines = useAppSelector(selectBaseLines);
  const batchInformation = useAppSelector(state => state.form.batchForm);
  const selectedBaseline = (baselines || []).find(
    b =>
      b.id === formValues?.fields?.sustainabilityCertification ||
      b.name === formValues?.fields?.sustainabilityCertification,
  );
  const isDateBlendAvailable = 
    formValues.fields?.endProduct === 'Blended' && 
    formValues.fields?.radioButton === 'Self-production';

  const initialValues: MyBatchFormValue = {
    batchInformation: batchInformation.map(batch => ({ ...batch })),
  };
  const formik = useFormik({
    initialValues,
    onSubmit: values => {},
  });

  const getFilteredData = (value: string) => {
    return (
      value.split('').filter(item => item === '.' || item === ',').length >= 2
    );
  };

  const handleFieldChange = (
    index: number,
    field: keyof MyBatchFormValue['batchInformation'][0],
    value: string,
  ) => {
    if (field === 'fieldCO2') {
      const formattedValue = value;
      // const isPoint = getFilteredData(value);

      // if (value.includes('.') && value.split('.')[1].length > 2) return;
      // if (isPoint) return;

      dispatch(
        updateBatchField({
          batchIndex: index,
          fieldName: field,
          value: formattedValue,
        }),
      );
      formik.setFieldValue(
        `batchInformation.${index}.${field}`,
        formattedValue,
      );

      const baselineValue = selectedBaseline
        ? selectedBaseline.baselineValue
        : undefined;

      if (baselineValue !== undefined) {
        const convertedValue = convertToNumber(value);
        const fieldGHGValue = convertToString(
          (100 - (100 * convertedValue) / baselineValue).toLocaleString(
            undefined,
            {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3,
            },
          ),
        );
        dispatch(
          updateBatchField({
            batchIndex: index,
            fieldName: 'fieldGHG',
            value: fieldGHGValue,
          }),
        );
        formik.setFieldValue(
          `batchInformation.${index}.fieldGHG`,
          fieldGHGValue,
        );
      }
    } else if (field === 'fieldSaf') {
      formik.setFieldValue(`batchInformation.${index}.fieldSaf`, value);
      dispatch(
        updateBatchField({
          batchIndex: index,
          fieldName: 'fieldSaf',
          value: value,
        }),
      );
    } else if (field === 'fieldTonnes') {
      const formattedValue = value;
      // const isPoint = getFilteredData(value);

      // if (isPoint) return;
      // if (value.includes('.') && value.split('.')[1].length > 5) return;

      formik.setFieldValue(
        `batchInformation.${index}.fieldTonnes`,
        formattedValue,
      );
      dispatch(
        updateBatchField({
          batchIndex: index,
          fieldName: 'fieldTonnes',
          value: formattedValue,
        }),
      );
    } else if (field === 'fieldMJ') {
      const regex = /^[0-9]+$/;

      if (!regex.test(value.replaceAll(',', '')) && value) return;

      formik.setFieldValue(`batchInformation.${index}.fieldMJ`, value);
      dispatch(
        updateBatchField({
          batchIndex: index,
          fieldName: 'fieldMJ',
          value: value,
        }),
      );
    } else if (field === 'fieldID') {
      if (value.length <= 100) {
        formik.setFieldValue(`batchInformation.${index}.fieldID`, value);
        dispatch(
          updateBatchField({
            batchIndex: index,
            fieldName: 'fieldID',
            value: value,
          }),
        );
      }
    } else {
      dispatch(
        updateBatchField({ batchIndex: index, fieldName: field, value: value }),
      );
      formik.setFieldValue(`batchInformation.${index}.${field}`, value);
    }
  };

  const handleAddBatch = () => {
    const newBatch = {
      ...formik.values.batchInformation[0],
      ...{
        fieldID: '',
        fieldTonnes: '',
        fieldMJ: '',
        dateBlend: '',
        fieldCO2: '',
        fieldGHG: '',
      },
    };

    const batches = Object.keys(newBatch).map((key: any) => ({
      batchIndex: formik.values.batchInformation.length,
      fieldName: key,
      // @ts-ignore
      value: newBatch[key],
    }));

    formik.setFieldValue('batchInformation', [
      ...formik.values.batchInformation,
      newBatch,
    ]);

    setBatchNumber(batchIndex + 1);
    setBatchIndex(batchIndex + 1);
    dispatch(addBatch());
    batches.forEach(({ batchIndex, fieldName, value }) =>
      dispatch(updateBatchField({ batchIndex, fieldName, value })),
    );
  };

  const handleRemoveBatch = (batchIndex: number): void => {
    const updatedBatchInformation = [...formik.values.batchInformation];
    updatedBatchInformation.splice(batchIndex, 1);
    formik.setFieldValue('batchInformation', updatedBatchInformation);
    dispatch(removeBatch(batchIndex));
  };

  const isRequiredFieldEmpty = () => {
    const requiredFields: Array<keyof MyBatchFormValue['batchInformation'][0]> =
      [
        'fieldID',
        'fieldTonnes',
        'fieldMJ',
        'dateProd',
        'fieldCO2',
        'fieldBatchCountryOf',
        // 'fieldSaf',
      ];

    if (isDateBlendAvailable) {
      requiredFields.push('dateBlend');
    }

    return formik.values.batchInformation.some(batch =>
      requiredFields.some(field => !batch[field]),
    );
  };

  return {
    formik,
    batchIndex,
    handleFieldChange,
    handleAddBatch,
    isRequiredFieldEmpty,
    handleRemoveBatch,
  };
};
