import { FC, useEffect, useState } from 'react';
import './RegistrationFromStyles.scss';

import BCURegistrationForm, { BCU_SOURCE } from './components/FormReg';
import BCUFormSteps from './components/Steps';
import { Company } from '../../../structure/models/company/company';
import { Summary } from './components/Summary';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { transformData } from '../../dashboards/UserDashboard/BCURegistration/models/validation';
import { fetchBCURegistration } from '../../../API/credits';
import { UserBalance } from '../UserBalance/UserBalance';
import { selectUser } from '../../../store/reducers/user/userReducer';
import convertToString from '../../../helpers/convertToString';
import { useLocation, useNavigate } from 'react-router-dom';
import { REGISTER_CREDIT, STATEMENTS } from '../../../structure/routes/routes';
import { clearFormFields, partialClearFormFields, setShouldValidate } from '../../../store/reducers/user/bcuFormReducer';
import { selectBaseLines } from '../../../store/reducers/user/abatementBaselinesReducer';
import { fetchCompanyData } from '../../../API/user';
import { useNotification } from '../../../hooks/useNotification';

interface IRegistrationFormProps {
  company?: Company;
}

export const RegistrationForm: FC<IRegistrationFormProps> = ({ company }) => {
  const toast = useNotification();
  const { pathname } = useLocation();
  const user = useAppSelector(selectUser);
  const fields = useAppSelector(state => state.form.fields);
  const batchFields = useAppSelector(state => state.form.batchForm);
  const neatProductSource = useAppSelector(
    state => state.form.fields.radioButton,
  );
  const uploadedExcel = useAppSelector(state => state.form.uploadedExcel);
  const baselines = useAppSelector(selectBaseLines);
  const [step, setStep] = useState<number>(1);
  const dispatch = useAppDispatch();
  const [fileUploadFirst, setFileUploadFirst] = useState<File | null>(null);
  const [fileUploadSecond, setFileUploadSecond] = useState<File | null>(null);
  const [dragAndDropFirst, setDragAndDropFirst] = useState<File | null>(null);
  const [dragAndDropSecond, setDragAndDropSecond] = useState<File | null>(null);
  const [batchNumber, setBatchNumber] = useState<number>(0);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleStep, setIsVisibleStep] = useState<number[]>([0]);
  const [BCUSource, setBCUSource] = useState<BCU_SOURCE>(BCU_SOURCE.MANUAL);

  const isRegistrationAllowed =
    new Date(user?.company?.certificateValidityEndDate || Date.now()) >
    new Date();

  const handleNext = () => {
    setIsVisibleStep([...isVisibleStep, step]);
    setStep(step + 1);
  };

  const handleEdit = () => {
    if (uploadedExcel.length > 0) { 
      setIsVisibleStep([1]);
      setBCUSource(BCU_SOURCE.EXCEL);
    }
    setStep(1);
  };

  const handleCurrentStep = (newStep: number) => {
    setStep(newStep);
  };

  const handleSubmit = () => {
    try {
      setIsDisabled(true);
      setIsLoading(true);
      const formData = new FormData();
      //GenForm
      formData.append('productType', fields.endProductType);
      formData.append('rawMaterial', fields.rawMaterialFeedstock);
      formData.append(
        'certificationScheme',
        fields.sustainabilityCertification,
      );
      formData.append('certificationBody', fields.fieldCertif);
      formData.append(
        'sustainabilityCertificationNumber',
        fields.sustainabilityCertificationNumber,
      );
      formData.append('feedstockOriginWithSCSDetail', fields.fieldBatchCountry);
      formData.append(
        'lowerHeatingValue',
        fields.lowerHeatingValue.replaceAll(',', ''),
      );
      formData.append('registerRole', fields.radioButton);
      formData.append('feedstockConversionProcess', fields.conversionProcess);
      formData.append('originalPOSNumber', fields.fieldPoSNumber);
      formData.append('feedstockCertification', fields.feedstockCertification);
      //SelForm
      formData.append('supplierName', fields.fieldName);
      formData.append('supplierAddress', fields.fieldStreet1);
      formData.append('supplierAddressNumber', fields.fieldStreet2);
      formData.append('supplierZip', fields.fieldPost);
      formData.append('supplierCity', fields.fieldCity);
      formData.append('deliveryCountry', fields.fieldCountry);
      formData.append('supplierCountry', fields.fieldCountryOf);
      formData.append('chainCustodyModel', fields.fieldChain);
      formData.append('companyName', fields.companyName);
      formData.append('posIssueDate', fields.posIssueDate);
      formData.append('supPosIssueDate', fields.supPosIssueDate);
      //NeatForm(new fields)
      if (neatProductSource !== 'Self-production') {
        formData.append('acquisitionDate', fields.dateAcq);
        formData.append('entryInPOChainDate', fields.dateEntry);
        formData.append('fieldPoSID', fields.fieldPoS);
      }
      //BlendForm
      formData.append('endProduct', fields.endProduct);

      formData.append('blendFieldName', fields.blendFieldName);
      formData.append('blendFieldStreet1', fields.blendFieldStreet1);
      formData.append('blendFieldStreet2', fields.blendFieldStreet2);
      formData.append('blendFieldPost', fields.blendFieldPost);
      formData.append('blendFieldCity', fields.blendFieldCity);
      formData.append('blendFieldCountry', fields.blendFieldCountry);
      //Final Site Information
      formData.append('finalBlendFieldName', fields.finalBlendFieldName);
      formData.append('finalBlendFieldStreet1', fields.finalBlendFieldStreet1);
      formData.append('finalBlendFieldStreet2', fields.finalBlendFieldStreet2);
      formData.append('finalBlendFieldPost', fields.finalBlendFieldPost);
      formData.append('finalBlendFieldCity', fields.finalBlendFieldCity);
      formData.append('finalBlendFieldCountry', fields.finalBlendFieldCountry);
      //BatchForm
      const batchInformation = [];

      for (let i = 0; i < batchNumber + 1; i++) {
        const batch = {
          fieldID: batchFields[i].fieldID,
          dateBlend: batchFields[i].dateBlend,
          entryBlendDate: batchFields[i].entryBlendDate,
          productDeliveryDate: batchFields[i].productDeliveryDate,
          dateProd: batchFields[i].dateProd,
          volume: batchFields[i].fieldTonnes.replaceAll(',', ''),
          neatAmountInMJ: batchFields[i].fieldMJ.replaceAll(',', ''),
          fieldBatchCountry: fields.fieldBatchCountry,
          fieldCO2: batchFields[i].fieldCO2.replaceAll(',', ''),
          fieldGHG: batchFields[i].fieldGHG.replaceAll(',', ''),
          fieldBatchCountryOf: batchFields[i].fieldBatchCountryOf,
          fieldSaf: batchFields[i].fieldSaf,
          fieldRsb: batchFields[i].fieldRsb,
          fieldDec: batchFields[i].fieldDec,
        };

        batchInformation.push(batch);
      }

      formData.append('batchInformation', JSON.stringify(batchInformation));
      // //SusForm
      // formData.append('neatSAFProductionDate', fields.dateSustainabilitySAF);
      formData.append('defaultILUCValue', fields.fieldSustainabilityCO2);
      formData.append(
        'directLandUseChangeDisaggregatedValue',
        fields.fieldSustainabilityLand,
      );
      formData.append(
        'landfillDirectionCreditDisaggregatedValue',
        fields.fieldSustainabilityLandfill,
      );
      formData.append(
        'recyclingEmissionCreditDisaggregatedValue',
        fields.fieldSustainabilityRecycling,
      );
      //Total
      formData.append('LCAGHGValue', fields.LCAGHGValue.replaceAll(',', ''));
      formData.append(
        'GHGEmissionReduction',
        fields.GHGEmissionReduction.replaceAll(',', ''),
      );
      formData.append(
        'neatAmountInMJ',
        fields.neatAmountInMJ.replaceAll(',', ''),
      );
      formData.append('volume', convertToString(fields.volume));
      formData.append(
        'abatementBaselineScheme',
        fields.abatementBaselineScheme,
      );

      if (fileUploadFirst) {
        formData.append('posFile', fileUploadFirst);
      }

      if (dragAndDropFirst) {
        formData.append('posFile', dragAndDropFirst);
      }

      if (fileUploadSecond) {
        formData.append('supplierFile', fileUploadSecond);
      }

      if (dragAndDropSecond) {
        formData.append('supplierFile', dragAndDropSecond);
      }

      dispatch(fetchBCURegistration(transformData(formData)))
        .then(() => {
          toast.success({
            message: 'Success',
            description:
              'Your request has been sent for administrator review. You will receive an email notification after validation.',
          });
          navigate(STATEMENTS);
          dispatch(fetchCompanyData());
          dispatch(clearFormFields());
          dispatch(setShouldValidate(false));
        })
        .catch(e => {
          const errorMessage = e?.response?.data?.message;

          if (typeof errorMessage === 'string') {
            toast.error({ message: 'Error', description: errorMessage });
          } else if (Array.isArray(errorMessage)) {
            toast.error({
              message: 'Error',
              description: `Validation error. Please make sure the fields are filled out correctly: \n ${errorMessage.join(
                '\n',
              )}`,
            });
          } else {
            toast.error({
              message: 'Error',
              description:
                'Validation error. Please make sure the fields are filled out correctly',
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsDisabled(false);
        });
    } catch {
      toast.error({ message: 'Error', description: 'Something went wrong' });
    }
  };

  useEffect(() => {
    return () => {
      if (!pathname.split('/').includes(REGISTER_CREDIT))
        dispatch(clearFormFields());
    };
  }, []);

  const selectedBaseline = (baselines || []).find(
    b => b.id === fields.sustainabilityCertification,
  );

  const hasCORSIAStep =
    selectedBaseline &&
    selectedBaseline.name.length > 0 &&
    String(selectedBaseline.name).includes('CORSIA');
  
  const lastStep = BCUSource === BCU_SOURCE.EXCEL ? 2 : hasCORSIAStep ? 6 : 5;

  return (
    <div className="main-reg">
      <UserBalance company={user!.company} style={{ width: '100%' }} />
      {step !== lastStep ? (
        <div className="second-reg">
          <BCUFormSteps
            BCUSource={BCUSource}
            neatProductSource={neatProductSource}
            step={step}
            handleCurrentStep={handleCurrentStep}
            batchNumber={batchNumber}
            isVisibleStep={isVisibleStep}
          />
          <BCURegistrationForm
            BCUSource={BCUSource}
            setBCUSource={setBCUSource}
            setBatchNumber={setBatchNumber}
            batchNumber={batchNumber}
            neatProductSource={neatProductSource}
            step={step}
            handleSetStep={handleCurrentStep}
            handleClick={handleNext}
            onFileUploadFirst={setFileUploadFirst}
            onDragAndDropFirst={setDragAndDropFirst}
            onFileUploadSecond={setFileUploadSecond}
            onDragAndDropSecond={setDragAndDropSecond}
            dragAndDrop={dragAndDropFirst}
            fileUpload={fileUploadFirst}
            isDisabled={!isRegistrationAllowed}
          />
        </div>
      ) : (
        <Summary
          handleClick={handleEdit}
          handleSubmit={handleSubmit}
          disabled={isDisabled}
          loading={isLoading}
        />
      )}
    </div>
  );
};
