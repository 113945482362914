import { FC } from "react";
import "./susStyles.scss";
import SummaryField from "../../SummaryField";
import { useAppSelector } from "../../../../../../store/hooks";



interface Props {
    title: string;
  }

  const SusSum: FC<Props> = ({ title }) => {
    const formValues = useAppSelector((state) => state.form.fields);

    const truncateNumber = (numberStr: string, decimalPlaces: number): string => {
      if (!numberStr) return '';
      const [integerPart, fractionalPart = ''] = numberStr.split('.');
    
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
      if (decimalPlaces === 0) {
        return formattedIntegerPart;
      }
    
      const paddedFraction =
        fractionalPart.length < decimalPlaces
          ? fractionalPart.padEnd(decimalPlaces, '0')
          : fractionalPart.slice(0, decimalPlaces);
    
      return `${formattedIntegerPart}.${paddedFraction}`;
    };

    return (
      <div className="form-sum1">
        <h2 className="form-title">{title}</h2>
        {/* <SummaryField
          nameProp="Production date of neat SAF"
          selected={formValues.dateSustainabilitySAF}
          border
        /> */}
        <SummaryField
          nameProp={<span>Default ILUC value (gCO<sub>2</sub>eg/MJ)</span>}
          selected={truncateNumber(formValues.fieldSustainabilityCO2, 3)}
          border
        />
        <SummaryField
          nameProp="If applicable, disaggregated value for Direct Land Use Change"
          selected={truncateNumber(formValues.fieldSustainabilityLand, 3)}
          border
        />
        <SummaryField
          nameProp="If applicable, disaggregated value for Landfill Emission Credit"
          selected={truncateNumber(formValues.fieldSustainabilityLandfill, 3)}
          border
        />
        <SummaryField
          nameProp="If applicable, disaggregated value Recycling Emission Credit (REC)"
          selected={truncateNumber(formValues.fieldSustainabilityRecycling, 3)}
        />
      </div>
    );
  };

export default SusSum;