import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import { MyFormValues } from './types';
import NextButton from '../../../../Buttons/NextButton/NextButton';
import FormTitle from '../../FormTitle/FormTitle';
import Field from '../../Field/Field';
import { COUNTRY, END_PRODUCT_INFO } from '../../../../../dashboards/UserDashboard/BCURegistration/models/options.constant';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  setField,
  setFileDataTwoStep,
  setFileUploadedTwoStep,
} from '../../../../../../store/reducers/user/bcuFormReducer'
import {
  handleEnterLatinAndNumbers,
  handleEnterLatinLetters,
  handleEnterNumbersAndSlash,
  handleEnterNumbers
} from '../../../../../../helpers/handleKeyPress';
import { DefaultSelect } from '../../../../Form/DefaultSelect/DefaultSelect';
import PdfFileUpload from '../../PdfFileUpload/PdfFileUpload';
import { selectUser } from '../../../../../../store/reducers/user/userReducer';
import { NEAT_SOURCE } from '../ExcelSource';

import './BlendAccountFormStyles.scss';

interface IBlendAccountFormProps {
  handleClick: () => void;
  onFileUpload: (file: File) => void;
  onDragAndDrop: (file: File | null) => void;
}

const BlendAccountForm: FC<IBlendAccountFormProps> = ({ 
  handleClick,
  onFileUpload,
  onDragAndDrop,
 }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const formValues = useAppSelector(state => state.form);
  const [errorVisible, setErrorVisible] = useState(false);

  const isFileUploaded = formValues.isFileUploadedTwoStep;

  const handleValidation = (values: any) => {
    const errors = {} as any;

    if (!values.endProduct) {
      errors.endProduct = 'End product is required';
    }

    if (values.blendFieldName.length === 0) {
      errors.blendFieldName = 'Field Name is required';
    } else if (values.blendFieldName.length < 2) {
      errors.blendFieldName = 'Field Name must have at least 2 characters';
    }
    if (!values.blendFieldStreet1) {
      errors.blendFieldStreet1 = 'Field Street address is required';
    }
    if (!values.blendFieldCountry) {
      errors.blendFieldCountry = 'Field Country is required';
    }
    if (!values.blendFieldPost || values.blendFieldPost.length < 3 || values?.blendFieldPost?.length > 10) {
      errors.blendFieldPost = 'Field must have at least 3 characters and no longer then 10 characters';
    }
    if (!values.blendFieldCity) {
      errors.blendFieldCity = 'Field City is required';
    }

    if (values.radioButton === 'Supplier' || values.radioButton === 'Acquired') {
      if (!values.fieldPoS || values.fieldPoS.length < 3 ) {
        errors.fieldPoS = 'Field PoS ID must be longer than or equal to 3 characters';
      }
      if (!values.supPosIssueDate) {
        errors.supPosIssueDate = 'Please enter supplier issue date';
      }
      if (!isFileUploaded) {
        errors.fieldUpDate = 'Please upload pdf file';
      }
    }
    
    formik.setErrors(errors);

    return errors;
  };

  const handleSameAddressChange = () => {
    if (user?.company) {
      const { address, addressNumber, country, zip, city, name } = user.company;
      dispatch(setField({ fieldName: 'blendFieldName', value: name }));
      dispatch(setField({ fieldName: 'blendFieldStreet1', value: address }));
      dispatch(setField({ fieldName: 'blendFieldStreet2', value: addressNumber || '' }));
      dispatch(setField({ fieldName: 'blendFieldCountry', value: country }));
      dispatch(setField({ fieldName: 'blendFieldPost', value: zip }));
      dispatch(setField({ fieldName: 'blendFieldCity', value: city }));

      formik.setFieldValue('blendFieldName', name);
      formik.setFieldValue('blendFieldStreet1', address);
      formik.setFieldValue('blendFieldStreet2', addressNumber);
      formik.setFieldValue('blendFieldCountry', country);
      formik.setFieldValue('blendFieldPost', zip);
      formik.setFieldValue('blendFieldCity', city);
    }
  }

  const handleStepTwoAddressChange = () => {
    const { fieldName, fieldStreet1, fieldStreet2, fieldCountry, fieldPost, fieldCity } = formValues.fields;
    dispatch(setField({ fieldName: 'blendFieldName', value: fieldName }));
    dispatch(setField({ fieldName: 'blendFieldStreet1', value: fieldStreet1 }));
    dispatch(setField({ fieldName: 'blendFieldStreet2', value: fieldStreet2 || '' }));
    dispatch(setField({ fieldName: 'blendFieldCountry', value: fieldCountry }));
    dispatch(setField({ fieldName: 'blendFieldPost', value: fieldPost }));
    dispatch(setField({ fieldName: 'blendFieldCity', value: fieldCity }));
    
    formik.setFieldValue('blendFieldName', fieldName);
    formik.setFieldValue('blendFieldStreet1', fieldStreet1);
    formik.setFieldValue('blendFieldStreet2', fieldStreet2);
    formik.setFieldValue('blendFieldCountry', fieldCountry);
    formik.setFieldValue('blendFieldPost', fieldPost);
    formik.setFieldValue('blendFieldCity', fieldCity);
  }

  const handleClearSameAddress = () => {
    [
      'blendFieldName',
      'blendFieldStreet1',
      'blendFieldStreet2',
      'blendFieldCountry',
      'blendFieldPost',
      'blendFieldCity',
    ].forEach(field => {
      dispatch(
        setField({ fieldName: field as keyof MyFormValues, value: '' }),
      );
      formik.setFieldValue(field, '');
    });
  }

  const formik = useFormik({
    initialValues: formValues.fields,
    onSubmit: values => {},
  });

  const handleFieldChange = (field: keyof MyFormValues, value: string) => {
    dispatch(setField({ fieldName: field, value: value }));
    formik.setFieldValue(field, value);
  };

  const setEmptyAddress = () => {
    [
      'blendFieldName',
      'blendFieldStreet1',
      'blendFieldStreet2',
      'blendFieldCountry',
      'blendFieldPost',
      'blendFieldCity',
      'fieldPoS',
      'supPosIssueDate',
    ].forEach(field => {
      dispatch(
        setField({ fieldName: field as keyof MyFormValues, value: '' }),
      );
      formik.setFieldValue(field, '');
    });

    formik.setFieldValue('fieldUpDate', '');
    dispatch(setFileDataTwoStep(''));
    dispatch(setFileUploadedTwoStep(false));
  }

  const handleProvideInfoChange = (value: string) => {
    const isAcquiredSelected = formik.values.radioButton === 'Supplier';

    handleFieldChange('endProduct', value);
    
    if (!isAcquiredSelected) {
      setEmptyAddress();
    }
  };

  const handleFormClick = () => {
    const errors = handleValidation(formik.values);
    const isFormValid = !Object.keys(errors).length;

    setErrorVisible(!isFormValid);
    if (isFormValid) {
      handleClick();
    }
  };
  
  const isSupplier =
    formValues.fields.radioButton === 'Supplier' ||
    formValues.fields.radioButton === NEAT_SOURCE.ACQUIRED;

  useEffect(() => {
    if (formValues.shouldValidate) {
      handleFormClick();
    }
  }, [formValues.shouldValidate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <FormTitle title={`3. Product information`} />

      <DefaultSelect
        required
        isSearchable
        inputId="endProduct"
        name="endProduct"
        label="End product"
        placeholder="End product"
        error={formik.errors.endProduct}
        options={END_PRODUCT_INFO as any}
        value={formik.values.endProduct}
        onChange={(option: any) => handleProvideInfoChange(option.value)}
      />

      <form onSubmit={formik.handleSubmit}>
        <div className="second">
          <p className="rad">
            Product source<span style={{ color: 'red' }}>*</span>
          </p>
          <label className="rad-1">
            <input
              type="radio"
              name="radioButton"
              value="Supplier"
              checked={
                formik.values.radioButton === 'Supplier' ||
                formik.values.radioButton === NEAT_SOURCE.ACQUIRED
              }
              onChange={() => handleFieldChange('radioButton', 'Supplier')}
            />
            <span className="input">Acquired</span>
          </label>
          <label className="rad-2">
            <input
              type="radio"
              name="radioButton"
              value="Self-production"
              checked={
                formik.values.radioButton === 'Self-production' ||
                formik.values.radioButton === NEAT_SOURCE.PRODUCED
              }
              onChange={() => {
                handleFieldChange('radioButton', 'Self-production');
                setEmptyAddress();
              }}
            />
            <span className="input">Produced</span>
          </label>
          {formik.errors.radioButton && (
            <p className="field-error-text">{formik.errors.radioButton}</p>
          )}
          
          {/*
          Commented until we get new desc from Max 
          <p className="generalStep-info">
            Supplied SAF shall be selected, if a PoS/PoC has been issued for the
            neat SAF. Neat SAF shall be selected, if the neat SAF has been only
            tracked by an internal ERP system.
          </p> */}
        </div>
              
        {isSupplier && (
          <div className='subtitle'>
            <FormTitle title='Supplier information' />
          </div>
        )}

        {!isSupplier && (
          <div className='final-production'>
            <div className='subtitle'>
              <FormTitle title='Final Production Site Information' />
            </div>
            <p className='subtitle-text'>Provide blending site information if blended, co-processing site information if co-processed, or neat product production site if unblended</p>
          
            <div className='address-selection-wrap'>
              <label className="rad-1">
                <input
                  type="radio"
                  name="finalProductionSiteInfo"
                  value="SameAccountAddress"
                  checked={formik.values.finalProductionSiteInfo === 'SameAccountAddress'}
                  onChange={() => {
                    handleFieldChange('finalProductionSiteInfo', 'SameAccountAddress')
                    handleSameAddressChange();
                  }}
                />
                <span className="input">same as account address</span>
              </label>
              <label className="rad-1">
                <input
                  type="radio"
                  name="finalProductionSiteInfo"
                  value="SameProductionAddress"
                  checked={formik.values.finalProductionSiteInfo === 'SameProductionAddress'}
                  onChange={() => {
                    handleFieldChange('finalProductionSiteInfo', 'SameProductionAddress')
                    handleStepTwoAddressChange();
                  }}
                />
                <span className="input">same as production site address</span>
              </label>
              <label className="rad-1">
                <input
                  type="radio"
                  name="finalProductionSiteInfo"
                  value="NewAddress"
                  checked={formik.values.finalProductionSiteInfo === 'NewAddress'}
                  onChange={() => {
                    handleFieldChange('finalProductionSiteInfo', 'NewAddress')
                    handleClearSameAddress();
                  }}
                />
                <span className="input">new address</span>
              </label>
            </div>
          </div>
        )}

        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          required
          label="Name"
          placeholder="Name"
          id="fieldName"
          value={formik.values.blendFieldName}
          onChange={e => handleFieldChange('blendFieldName', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.blendFieldName}
          errorVisible={errorVisible}
        />

        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          required
          label="Street address"
          placeholder="Street address"
          id="fieldStreet1"
          value={formik.values.blendFieldStreet1}
          onChange={e => handleFieldChange('blendFieldStreet1', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.blendFieldStreet1}
          errorVisible={errorVisible}
        />

        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          label="Street address line 2"
          placeholder="Street address line 2"
          id="fieldStreet2"
          value={formik.values.blendFieldStreet2}
          onChange={e => handleFieldChange('blendFieldStreet2', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.blendFieldStreet2}
          errorVisible={errorVisible}
        />

        <div className="field-cp">
          <Field
            handleKeyPress={handleEnterLatinAndNumbers}
            required
            label="Post Code"
            placeholder="Post Code"
            id="fieldPost"
            value={formik.values.blendFieldPost}
            onChange={e => handleFieldChange('blendFieldPost', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.errors.blendFieldPost}
            errorVisible={errorVisible}
          />

          <Field
            handleKeyPress={handleEnterLatinLetters}
            required
            label="City"
            placeholder="City"
            id="fieldCity"
            value={formik.values.blendFieldCity}
            onChange={e => handleFieldChange('blendFieldCity', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.errors.blendFieldCity}
            errorVisible={errorVisible}
          />
        </div>

        <DefaultSelect
          required
          isSearchable
          inputId="fieldCountry"
          name="fieldCountry"
          label="Country"
          placeholder="Country"
          error={formik.errors.blendFieldCountry}
          options={COUNTRY as any}
          value={formik.values.blendFieldCountry}
          onChange={(option: any) => {
            handleFieldChange('blendFieldCountry', option.value);
          }}
        />

        {isSupplier && (
          <>
            <div className='subtitle'>
              <FormTitle title={`PoS provided by supplier`} />
              <Field
                handleKeyPress={handleEnterNumbersAndSlash}
                required
                label="PoS ID number"
                placeholder="PoS ID number"
                id="fieldPoS"
                showOptional={false}
                value={formik.values.fieldPoS}
                onChange={e => handleFieldChange('fieldPoS', e.target.value)}
                onBlur={formik.handleBlur}
                error={formik.errors.fieldPoS}
                errorVisible={errorVisible}
              />
              <Field
                required
                handleKeyPress={handleEnterNumbers}
                type="date"
                label="PoS provided by Supplier issue date"
                id="supPosIssueDate"
                value={formik.values.supPosIssueDate}
                max={format(new Date(), 'yyyy-MM-dd')}
                onChange={e => handleFieldChange('supPosIssueDate', e.target.value)}
                onBlur={formik.handleBlur}
                error={formik.errors.supPosIssueDate}
                errorVisible={errorVisible}
              />
              <PdfFileUpload
                setFieldValue={formik.setFieldValue}
                onDragAndDrop={onDragAndDrop}
                onFileUpload={onFileUpload}
                label="PoS pdf upload"
                required
                id="fieldUpDate"
                name="fieldUpDate"
                error={formik.errors.fieldUpDate}
                errorVisible={errorVisible}
              />
            </div>
          </>
        )}

        <NextButton handleClick={handleFormClick} text="Next step" />
      </form>
    </div>
  );
};

export default BlendAccountForm;
