import React, { useState } from 'react';
import { BaseButtonSubmit } from '../BaseButtonSubmit/BaseButtonSubmit';

import './DownloadFileButtonStyle.scss';

interface Props {
  fetchDownload: () => Promise<void>;
  title?: string;
}

export const DownloadFileButton: React.FC<Props> = ({
  fetchDownload,
  title = 'Download Data',
}) => {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    fetchDownload().then(() => {
      setLoading(false);
    });
  };

  return (
    <BaseButtonSubmit
      active
      lock={loading}
      loading={loading}
      className="download-file-button"
      onClick={onClick}>
      <span className="material-icons-outlined">file_download</span>
      {title}
    </BaseButtonSubmit>
  );
};
