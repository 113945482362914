import { FC, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import NextButton from '../../../../Buttons/NextButton/NextButton';
import Field from '../../Field/Field';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { setField } from '../../../../../../store/reducers/user/bcuFormReducer';
import FormTitle from '../../FormTitle/FormTitle';
import {
  handleEnterLatinAndNumbers,
  handleEnterNumbers,
} from '../../../../../../helpers/handleKeyPress';
import { MyFormValues } from './types';

import './SustainabilityFormStyles.scss';

interface SustainabilityFormProps {
  handleClick: () => void;
}

const SustainabilityForm: FC<SustainabilityFormProps> = ({ handleClick }) => {
  const dispatch = useAppDispatch();
  const formValues = useAppSelector(state => state.form);

  const [errorVisible, setErrorVisible] = useState(false);

  const formik = useFormik({
    initialValues: formValues.fields,
    onSubmit: values => {},
  });

  const handleValidation = (values: any) => {
    const errors = {} as any;
    const numberRegex = /^\d{1,3}(,\d{3})*(\.\d{1,3})?$/;

    if (values.fieldSustainabilityCO2 && !numberRegex.test(values.fieldSustainabilityCO2)) {
      errors.fieldSustainabilityCO2 = 'Field must contain only numbers and can have up to 3 decimal places';
    }
  
    if (values.fieldSustainabilityLand && !numberRegex.test(values.fieldSustainabilityLand)) {
      errors.fieldSustainabilityLand = 'Field must contain only numbers and can have up to 3 decimal places';
    }
  
    if (values.fieldSustainabilityLandfill && !numberRegex.test(values.fieldSustainabilityLandfill)) {
      errors.fieldSustainabilityLandfill = 'Field must contain only numbers and can have up to 3 decimal places';
    }
  
    if (values.fieldSustainabilityRecycling && !numberRegex.test(values.fieldSustainabilityRecycling)) {
      errors.fieldSustainabilityRecycling = 'Field must contain only numbers and can have up to 3 decimal places';
    }
  
    formik.setErrors(errors);
    return errors;
  };

  const isRequiredFieldEmpty = () => {
      const requiredFields: Array<keyof MyFormValues> = [
        'fieldSustainabilityCO2',
        'fieldSustainabilityLand',
        'fieldSustainabilityLandfill',
        'fieldSustainabilityRecycling',
      ];
      return (
        requiredFields.some(field => !formik.values[field])
      );
  };

  const handleFieldChange = (field: keyof MyFormValues, value: string) => {
    dispatch(setField({ fieldName: field, value: value }));
    formik.setFieldValue(field, value);
  };

  const handleDecimalInput = (e: React.ChangeEvent<HTMLInputElement>, field: keyof MyFormValues) => {
    const value = e.target.value;
    // const decimalRegex = /^\d*\.?\d{0,3}$/;

    // if (decimalRegex.test(value)) {
      handleFieldChange(field, value);
    // }
  };

  const handleFormClick = async () => {
    const errors = await handleValidation(formik.values);
    const isErrors = !!Object.keys(errors).length;
    setErrorVisible(isErrors);

    if (!isErrors) {
      handleClick();
    }
    // if (Number(formik.values.sustainabilityCertification) === 5) {
    //   const hasEmptyRequiredFields = isRequiredFieldEmpty();
    //   setErrorVisible(hasEmptyRequiredFields);
    //   if (!hasEmptyRequiredFields) {
    //     handleClick();
    //   }
    // } else {
    //   handleClick();
    // }
  };

  useEffect(() => {
    if (formValues.shouldValidate) {
      handleFormClick();
    }
  }, [formValues.shouldValidate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <FormTitle title="5. ICAO CORSIA information" />
      <form onSubmit={formik.handleSubmit} className="form-style">
        {/* <Field
          handleKeyPress={handleEnterNumbers}
          label="Production date of neat SAF"
          id="dateSaf"
          onChange={e =>
            handleFieldChange('dateSustainabilitySAF', e.target.value)
          }
          value={formik.values.dateSustainabilitySAF}
          onBlur={formik.handleBlur}
          type="date"
          error={formik.errors.dateSustainabilitySAF}
          errorVisible={errorVisible}
        /> */}
        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          label={
            <span>
              Default ILUC value (gCO<sub>2</sub>eg/MJ)
            </span>
          }
          placeholder="Default ILUC value (gCO2eg/MJ)"
          id="fieldSustainabilityCO2"
          value={formik.values.fieldSustainabilityCO2}
          onChange={e => handleDecimalInput(e, 'fieldSustainabilityCO2')}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldSustainabilityCO2}
          errorVisible={Number(formik.values.sustainabilityCertification) === 5}
          formatOnBlur
          decimals={3}
        />
        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          label="If applicable, disaggregated value for Direct Land Use Change"
          placeholder="If applicable, disaggregated value for Direct Land Use Change"
          id="fieldLand"
          value={formik.values.fieldSustainabilityLand}
          onChange={e => handleDecimalInput(e, 'fieldSustainabilityLand')}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldSustainabilityLand}
          formatOnBlur
          decimals={3}
          errorVisible={Number(formik.values.sustainabilityCertification) === 5}
        />
        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          label="If applicable, disaggregated value for Landfill Emission Credit"
          placeholder="If applicable, disaggregated value for Landfill Emission Credit"
          id="fieldLandfill"
          value={formik.values.fieldSustainabilityLandfill}
          onChange={e => handleDecimalInput(e, 'fieldSustainabilityLandfill')}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldSustainabilityLandfill}
          decimals={3}
          formatOnBlur
          errorVisible={Number(formik.values.sustainabilityCertification) === 5}
        />
        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          label="If applicable, disaggregated value Recycling Emission Credit (Rec)"
          placeholder="If applicable, disaggregated value Recycling Emission Credit (Rec)"
          id="fieldRecycling"
          value={formik.values.fieldSustainabilityRecycling}
          onChange={e => handleDecimalInput(e, 'fieldSustainabilityRecycling')}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldSustainabilityRecycling}
          decimals={3}
          formatOnBlur
          errorVisible={Number(formik.values.sustainabilityCertification) === 5}
        />
        <NextButton
          handleClick={handleFormClick}
          text="Preview"
          style={{ width: '170px', marginLeft: '330px' }}
        />
      </form>
    </div>
  );
};

export default SustainabilityForm;
