export const transformData = (formData: FormData) => {
  const registerRole = formData.get('registerRole');
  //GenForm
  const productType = formData.get('productType');
  const rawMaterial = formData.get('rawMaterial');
  const certificationScheme = formData.get('certificationScheme');
  const certificationBody = formData.get('certificationBody');
  const feedstockConversionProcess = formData.get('feedstockConversionProcess');
  const originalPOSNumber = formData.get('originalPOSNumber');
  const sustainabilityCertificationNumber = formData.get(
    'sustainabilityCertificationNumber',
  );
  const lowerHeatingValue = formData.get('lowerHeatingValue');
  const feedstockCertification = formData.get('feedstockCertification');
  //SelForm
  const supplierName = formData.get('supplierName');
  const supplierAddress = formData.get('supplierAddress');
  const supplierAddressNumber = formData.get('supplierAddressNumber');
  const supplierZip = formData.get('supplierZip');
  const supplierCity = formData.get('supplierCity');
  const deliveryCountry = formData.get('deliveryCountry');
  const supplierCountry = ' ';
  const chainCustodyModel = formData.get('chainCustodyModel');
  const companyName = formData.get('companyName');

  //BlendForm
  const endProduct = formData.get('endProduct');

  const blendingName = formData.get('blendFieldName')
  const blendingAddress = formData.get('blendFieldStreet1')
  const blendingAddressNumber = formData.get('blendFieldStreet2')
  const blendingZip = formData.get('blendFieldPost')
  const blendingCity = formData.get('blendFieldCity')
  const blendingCountry = formData.get('blendFieldCountry')
  const neatProductSource = formData.get('registerRole');
  //BatchForm
  const batchInformation = formData.get('batchInformation');
  //Total
  const LCAGHGValue = formData.get('LCAGHGValue');
  const GHGEmissionReduction = formData.get('GHGEmissionReduction');
  const neatAmountInMJ = formData.get('neatAmountInMJ');
  const volume = formData.get('volume');

  // for (let i = 0; i < 1; i++) {
  //   const batch = {
  //     fieldID: formData.get(`fieldID${i}`),
  //     dateBlend: formData.get(`dateBlend${i}`),
  //     dateProd: formData.get(`dateProd${i}`),
  //     fieldTonnes: formData.get(`fieldTonnes${i}`),
  //     fieldMJ: formData.get(`fieldMJ${i}`),
  //     fieldBatchCountry: formData.get(`fieldBatchCountry${i}`),
  //     fieldCO2: formData.get(`fieldCO2${i}`),
  //     fieldGHG: formData.get(`fieldGHG${i}`),
  //     fieldBatchCountryOf: formData.get(`fieldBatchCountryOf${i}`),
  //     fieldSaf: formData.get(`fieldSaf${i}`),
  //     fieldRsb: formData.get(`fieldRsb${i}`),
  //     fieldDec: formData.get(`fieldDec${i}`),
  //   };
  //   batchInformation.push(batch);
  // }

  //SusForm
  // const neatSAFProductionDate = formData.get('neatSAFProductionDate');
  const defaultILUCValueStr = formData.get('defaultILUCValue');
  const defaultILUCValue = defaultILUCValueStr && typeof defaultILUCValueStr === 'string' ? parseFloat(defaultILUCValueStr.replace(/,/g, '')) : null;

  const directLandUseChangeDisaggregatedValueStr = formData.get('directLandUseChangeDisaggregatedValue');
  const directLandUseChangeDisaggregatedValue = directLandUseChangeDisaggregatedValueStr && typeof directLandUseChangeDisaggregatedValueStr === 'string' ? parseFloat(directLandUseChangeDisaggregatedValueStr.replace(/,/g, '')) : null;

  const landfillDirectionCreditDisaggregatedValueStr = formData.get('landfillDirectionCreditDisaggregatedValue');
  const landfillDirectionCreditDisaggregatedValue = landfillDirectionCreditDisaggregatedValueStr && typeof landfillDirectionCreditDisaggregatedValueStr === 'string' ? parseFloat(landfillDirectionCreditDisaggregatedValueStr.replace(/,/g, '')) : null;

  const recyclingEmissionCreditDisaggregatedValueStr = formData.get('recyclingEmissionCreditDisaggregatedValue');
  const recyclingEmissionCreditDisaggregatedValue = recyclingEmissionCreditDisaggregatedValueStr && typeof recyclingEmissionCreditDisaggregatedValueStr === 'string' ? parseFloat(recyclingEmissionCreditDisaggregatedValueStr.replace(/,/g, '')) : null;
  const abatementBaselineScheme = formData.get('abatementBaselineScheme');
  //File
  const posFile = formData.get('posFile');
  const posIssueDate = formData.get('posIssueDate');

  if (registerRole !== 'Self-production') {
    // add extra fields
    //NeatForm(new fields)
    const acquisitionDate = formData.get('acquisitionDate');
    const entryInPOChainDate = formData.get('entryInPOChainDate');
    const posID = formData.get('fieldPoSID');
    const supPosIssueDate = formData.get('supPosIssueDate');
    // new file
    const supplierFile = formData.get('supplierFile');
    const data = {
      //GenForm
      productType,
      rawMaterial,
      certificationScheme,
      certificationBody,
      registerRole,
      feedstockConversionProcess,
      originalPOSNumber,
      sustainabilityCertificationNumber,
      lowerHeatingValue,
      posIssueDate,
      feedstockCertification,
      //SelForm
      supplierName,
      supplierAddress,
      supplierAddressNumber,
      supplierZip,
      supplierCity,
      deliveryCountry,
      supplierCountry,
      chainCustodyModel,
      // supplierCompanyName,
      //NeatForm(new fields)
      acquisitionDate,
      entryInPOChainDate,
      posID,
      endProduct,
      companyName,
      supPosIssueDate,
      //BlendForm
      neatProductSource,
      blendingName,
      blendingAddress,
      blendingAddressNumber,
      blendingZip,
      blendingCity,
      blendingCountry,
      //BatchForm
      batchInformation,
      //SusForm
      // neatSAFProductionDate,
      defaultILUCValue,
      directLandUseChangeDisaggregatedValue,
      landfillDirectionCreditDisaggregatedValue,
      recyclingEmissionCreditDisaggregatedValue,
      //Total
      LCAGHGValue,
      GHGEmissionReduction,
      neatAmountInMJ,
      volume,
      abatementBaselineScheme,
      //File
      supplierFile,
      posFile,
    };

    Object.keys(data).forEach(key => {
      const item = data[key as keyof typeof data];
      if (!item || item === 'undefined') delete data[key as keyof typeof data];
    });

    return data;
  }

  const data = {
    //GenForm
    productType,
    rawMaterial,
    certificationScheme,
    certificationBody,
    registerRole,
    feedstockConversionProcess,
    originalPOSNumber,
    sustainabilityCertificationNumber,
    lowerHeatingValue,
    feedstockCertification,
    //SelForm
    supplierName,
    supplierAddress,
    supplierAddressNumber,
    supplierZip,
    supplierCity,
    deliveryCountry,
    supplierCountry,
    chainCustodyModel,
    // supplierCompanyName,
    //NeatForm(new fields)
    // acquisitionDate,
    // entryInPOChainDate,
    // fieldPoSID,
    posIssueDate,
    //BlendForm
    neatProductSource,
    blendingName,
    blendingAddress,
    blendingAddressNumber,
    blendingZip,
    blendingCity,
    blendingCountry,
    endProduct,
    companyName,
    //BatchForm
    batchInformation,
    //SusForm
    // neatSAFProductionDate,
    defaultILUCValue,
    directLandUseChangeDisaggregatedValue,
    landfillDirectionCreditDisaggregatedValue,
    recyclingEmissionCreditDisaggregatedValue,
    //Total
    LCAGHGValue,
    GHGEmissionReduction,
    neatAmountInMJ,
    volume,
    abatementBaselineScheme,
    //File
    // supplierFile,
    posFile,
  };

  Object.keys(data).forEach(key => {
    const item = data[key as keyof typeof data];
    if (!item || item === 'undefined') delete data[key as keyof typeof data];
  });

  return data;
};
